import Vue from 'vue';
import Vuex from 'vuex';

import accountsModule from './accounts/accounts-store';
import notificationsModule from './notifications-store';
import requestModule from './requests-store';
import routerModule from './router-store';
import queriesModule from './queries/queries-store';
// import ruleEngineModule from './rule-engine/rule-engine-store';
import skillsModule from './skills/skills-store';
import policiesModule from './policies/policy-store';
import skillPoliciesModule from './skillPolicies/skillPolicy-store';

Vue.use(Vuex);

const injectionObj = {
  $http: Vue.prototype.$http,
};

export default new Vuex.Store({
  strict: true,
  modules: {
    accounts: accountsModule(injectionObj),
    notifications: notificationsModule(),
    request: requestModule(),
    router: routerModule(),
    queries: queriesModule(injectionObj),
    // ruleEngine: ruleEngineModule(injectionObj),
    skills: skillsModule(injectionObj),
    policies: policiesModule(injectionObj),
    skillPolicies: skillPoliciesModule(injectionObj),
  },
});
