const createUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export default ({ $http }) => ({
  async executeAthenaQuery(context, payload) {
    await $http.post('/athena/v1/query', payload, {
      requestName: 'executeAthenaQuery',
    });
    context.dispatch('addToastNotifications', {
      text: 'Query executed successfully',
      timer: 4000,
    });
    context.dispatch('fetchQueryExecHistory', payload);
  },

  async regenerateReport(context, { id, timestamp }) {
    await $http.put(
      '/athena/v1/query',
      { id, timestamp },
      {
        requestName: 'regenerateReport',
      },
    );
    context.dispatch('addToastNotifications', {
      text: 'Report regeneration request placed successfully',
      timer: 4000,
    });
  },

  async fetchQueryExecHistory(context, payload) {
    let queryString = '';
    Object.keys(payload || {}).forEach((key) => {
      if (payload[key]) queryString += `&${key}=${payload[key]}`;
    });
    try {
      const { data } = await $http.get(`/athena/v1/query?${queryString}`, {
        requestName: 'fetchQueryExecHistory',
      });
      context.commit('setQueryExecHistoryInStore', data);
      return data;
    } catch (error) {
      context.commit('clearRequestState', 'fetchQueryExecHistory');
      return error;
    }
    // const { records } = data;
    // const isAnyReportBeingProcessed = records.find((x) => x.status !== 'completed');
    // console.log(isAnyReportBeingProcessed);
  },

  async uploadFileToS3(context, { file, path, type }) {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    let baseURL;
    if (type === 'immerse') {
      if (path === 'offcon' || path === 'spcl_program_master') {
        const { data } = await $http.get(`/admin/s3/signed-url?path=${path}.csv&action=upload`, {
          requestName: 'uploadFileToS3',
        });
        baseURL = data.url;
      } else if (path === 'bmc') {
        const uuid = createUUID();
        const { data } = await $http.get(`/admin/s3/signed-url?path=${path}/${year}/${month}/${day}/${uuid}.csv&action=upload`, {
          requestName: 'uploadFileToS3',
        });
        baseURL = data.url;
      } else if (path === 'bits_wave_source' || path === 'penetration_mss') {
        const { data } = await $http.get(`/admin/s3/signed-url?path=e360/gpdl/people/manual/${path}/${year}-${month <= 9 ? `0${month}` : month}-${day <= 9 ? `0${day}` : day}.csv&action=upload`, {
          requestName: 'uploadFileToS3',
        });
        baseURL = data.url;
      } else {
        const { data } = await $http.get(`/admin/s3/signed-url?path=${path}/${year}/${month}/${day}.csv&action=upload`, {
          requestName: 'uploadFileToS3',
        });
        baseURL = data.url;
      }
    } else if (type === 'solidify') {
      const { data } = await $http.get(`/admin/s3/signed-url?path=solidify/${path === '/' ? `${file.name}` : `${path}/${file.name}`}&action=upload`, {
        requestName: 'uploadFileToS3',
      });
      baseURL = data.url;
    }
    const instance = $http.create({
      baseURL,
      headers: { 'Content-Type': file.type },
    });

    delete instance.defaults.headers.common.Authorization;
    const { data: response } = await instance.put('', file, {
      requestName: 'uploadFileToS3',
    });

    return response;
  },

  async downloadFileFromS3(context, { file, bucketName }) {
    const { data } = await $http.get(`/admin/s3/signed-url?path=${file}&action=download&bucketName=${bucketName}`, {
      requestName: 'downloadFileFromS3',
    });

    return data.url;
  },

  // async getGroups(context) {
  //   const { data } = await $http.get('/athena/v1/report/special-program', {
  //     requestName: 'getGroups',
  //   });
  //   context.commit('setGroups', data);
  //   return data;
  // },

  async downloadLSFromS3(context, { file, bucketName }) {
    const instance = $http.create({
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Content-Type': 'application/xml',
      },
    });
    const { data } = await instance.get(`https://34dxuhfuyi.execute-api.ap-south-1.amazonaws.com/poc/learner-score-svc/sp/function-get-report?path=${file}&bucketName=genome-data-lake-core-poc-bucket`, {
      requestName: 'downloadLSFromS3',
    });
    // debugger
    console.log(data, 'data', bucketName);
    return data.url;
  },
  async fetchAllReportsLists(context) {
    const { data } = await $http.get('/athena/v1/report', {
      requestName: 'fetchAllReportsLists',
    });

    context.commit('setReportsListsInStore', data);
    return data;
  },

  async updateVersion(context, payload) {
    const { data } = await $http.put(
      '/core-svc-web-releases/v1/library',
      {
        customer: 'Genpact',
        appName: 'Genome',
        library: payload.library,
        source: 'npm',
        version: payload.updatedVersion,
        status: true,
      },
      {
        requestName: 'putVersion',
      },
    );
    if (data.message) {
      context.dispatch('addToastNotifications', {
        text: data.message || 'Processing your request',
        timer: 4000,
      });
    }
    return data;
  },

  async getLibraries(context) {
    context.commit('clearLibraries');
    const { data } = await $http.get('/core-svc-web-releases/v1/library?cust=genpact&app=genome', {
      requestName: 'fetchLibraries',
    });
    if (data && data.length) {
      data.map(async (library) => {
        const libData = await context.dispatch('getVersions', library);
        context.commit('setLibraries', { library, libData });
        return true;
      });
    }
    return data;
  },

  async getVersions(context, obj) {
    const { data } = await $http.get(`/core-svc-web-releases/v1/library/versions?library=${obj.library}`, {
      requestName: 'fetchVersions',
    });
    return data;
  },

  // fetch Learner Score Reports
  async fetchLSReports(context) {
    const instance = $http.create({
      baseURL: 'https://34dxuhfuyi.execute-api.ap-south-1.amazonaws.com/poc/learner-score-svc/sp/function/LSreportstatus-GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Content-Type': 'application/xml',
      },
    });
    const { data } = await instance.get('', {
      requestName: 'fetchLSReports',
    });

    context.commit('setLSReportsInStore', data);
    return data;
  },
  async executeLSQuery(context, payload) {
    const instance = $http.create({
      baseURL: 'https://34dxuhfuyi.execute-api.ap-south-1.amazonaws.com/poc/learner-score-svc/sp/function/executeLSReport-POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Content-Type': 'application/xml',
      },
    });
    const { data } = await instance.post(
      '',
      {
        query: {
          type: payload,
        },
      },
      {
        requestName: 'executeLSQuery',
      },
    );
    if (data.message) {
      context.dispatch('addToastNotifications', {
        text: 'Processing your report',
        timer: 4000,
      });
    }
    context.dispatch('fetchLSReports', payload);
    return data;
  },
});
