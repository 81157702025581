const { VUE_APP_BUILD } = process.env;

// let poolId = 'ap-south-1_jq7swnBSf';
// let userPoolClientId = '5ufrhio4i1oo31mg1be69158fh';
let poolId = 'ap-south-1_xlPqrN9ZD';
let userPoolClientId = '923ursorsbpek3fm2b43tp6nf';
let authDomain = 'api-sandbox.auth.ap-south-1.amazoncognito.com';
let redirectUrls = ['http://localhost:5000/', 'https://admin-sandbox.genome.genpact.com/'];

if (VUE_APP_BUILD === 'dev') {
  poolId = 'ap-south-1_zAceUj28Z';
  userPoolClientId = '3lg7rkuhkdpgdmnorig78gv4r6';
  authDomain = 'api-dev.auth.ap-south-1.amazoncognito.com';
  redirectUrls = ['http://localhost:5000/', 'https://admin-dev.genome.genpact.com/'];
} else if (VUE_APP_BUILD === 'uat') {
  poolId = 'ap-south-1_zpNtAXRXy';
  userPoolClientId = '7m4tm1eofma6d4n5b9r12b7gmj';
  authDomain = 'api-uat.auth.ap-south-1.amazoncognito.com';
  redirectUrls = ['http://localhost:5000/', 'https://admin-uat.genome.genpact.com/'];
} else if (VUE_APP_BUILD === 'prod') {
  poolId = 'ap-south-1_s9vDtOoLq';
  userPoolClientId = '1scfa7lmlqthatfsrms54uomtu';
  authDomain = 'api-production.auth.ap-south-1.amazoncognito.com';
  redirectUrls = ['http://localhost:5000/', 'https://admin.genome.genpact.com/'];
}

const awsmobile = {
  aws_project_region: 'ap-south-1',
  // "aws_cognito_identity_pool_id": "ap-south-1:a1114f89-e743-40b0-b484",
  // aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: poolId,
  aws_user_pools_web_client_id: userPoolClientId,
  oauth: {
    domain: authDomain,
    scope: [
      // "phone",
      'email',
      'openid',
      'profile',
      // "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: redirectUrls.join(','),
    redirectSignOut: redirectUrls.map((x) => `${x}signout/`).join(','),
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export default awsmobile;
