<template>
  <section class="sm:flex justify-between border-t items-center h-16 w-full text-gray-700 text-sm px-6">
    <div class="mx-2 lg:mx-6 my-2 sm:my-0 text-center sm:text-left">
      {{ pagination.firstItem + 1 }} -<span v-if="pagination.lastItem <= totalItems">{{ pagination.lastItem }}</span
      ><span v-else>{{ totalItems }}</span> of {{ totalItems }} Items
    </div>
    <div class="sm:flex items-center lg:mx-3 mx-2">
      <div class="flex items-center justify-center">
        <button @click="previousPage()" class="border focus:outline-none cursor-pointer hover:text-blue-500 mx-1 p-1 rounded text-gray-500">
          <ChevronLeftIcon class="text-gray-800" size="1.5x" />
        </button>
        <div
          @click="gotoPage(pageNum)"
          v-for="pageNum in pagination.totalPage"
          :key="pageNum"
          class="flex px-2 py-1 rounded hover:font-bold hover:text-blue-500 cursor-pointer text-center font-light"
          v-show="(pageNum <= pagination.currentPage + 2 && pageNum >= pagination.currentPage - 2) || pageNum === 1 || pageNum === pagination.totalPage"
          :class="{
            'font-semibold': pagination.currentPage === pageNum,
            elipsis: pagination.totalPage - pagination.currentPage > 3,
          }"
        >
          <p v-if="pageNum === pagination.totalPage" class="pr-2 tracking-widest">...</p>
          <p>{{ pageNum }}</p>
        </div>
        <button @click="nextPage()" class="border focus:outline-none cursor-pointer hover:text-blue-500 mx-1 p-1 rounded text-gray-500">
          <ChevronRightIcon class="text-gray-800" size="1.5x" />
        </button>
      </div>
      <!-- <div class="flex items-center justify-center py-2 sm:py-0">
          <span class="px-2 ml-1">Go to page</span>
          <input
            type="number"
            v-model="jumptoPageNo"
            placeholder="1"
            class="border-0 bg-[#f4f4f4] focus:outline-none w-8 ml-1 px-1 py-1 rounded text-center text-gray-500"
          />
          <div @click="jumptoPageGo()" class="focus:outline-none cursor-pointer w-10 pl-2 py-1 text-center">Go ></div>
        </div> -->
    </div>
  </section>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons';

export default {
  name: 'Pagination',
  data() {
    return {
      selectedTab: 'users',
      jumptoPageNo: 1,
      pagination: {
        itemLimit: this.itemsLimit,
        totalPage: Math.ceil(this.totalItems / this.itemsLimit),
        firstItem: 0,
        lastItem: this.itemsLimit, // same as itemLimit
        currentPage: 1,
      },
    };
  },
  props: {
    totalItems: {
      type: Number,
      default: 0,
      required: true,
    },
    itemsLimit: {
      type: Number,
      default: 50,
      required: true,
    },
    searchPolicy: {
      type: String,
      default: '',
    },
  },
  watch: {
    totalItems(val) {
      this.pagination.totalPage = Math.ceil(val / this.itemsLimit);
    },
    searchPolicy() {
      this.pagination.currentPage = 1;
      this.pagination.firstItem = 0;
      this.pagination.lastItem = this.itemsLimit;
      this.pagination.totalPage = Math.ceil(this.totalItems / this.itemsLimit);
      this.$emit('changePage', {
        skip: 0,
        take: this.itemsLimit,
      });
    },
  },
  components: {
    // Icon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  methods: {
    async jumptoPageGo() {
      if (this.jumptoPageNo > 0 && this.jumptoPageNo <= this.pagination.totalPage) {
        const numPage = parseInt(this.jumptoPageNo, 10);
        await this.gotoPage(numPage);
      } else {
        this.$store.dispatch('addToastNotifications', {
          text: 'Enter correct page number',
          type: 'error',
          timer: 2000,
        });
      }
    },
    async gotoPage(pageNum) {
      this.pagination.lastItem = pageNum * this.pagination.itemLimit;
      this.pagination.firstItem = this.pagination.lastItem - this.pagination.itemLimit;
      this.pagination.currentPage = pageNum;
      this.jumptoPageNo = pageNum;
      this.$emit('changePage', {
        skip: this.pagination.currentPage * this.pagination.itemLimit - this.pagination.itemLimit,
        take: this.pagination.itemLimit,
      });
    },
    async nextPage() {
      if (this.pagination.currentPage >= this.pagination.totalPage) {
        this.$store.dispatch('addToastNotifications', {
          text: "you're on last page",
          type: 'error',
          timer: 2000,
        });
      } else {
        this.pagination.currentPage += 1;
        this.pagination.firstItem += this.pagination.itemLimit;
        this.pagination.lastItem += this.pagination.itemLimit;
        this.jumptoPageNo = this.pagination.currentPage;
        this.$emit('changePage', {
          skip: this.pagination.currentPage * this.pagination.itemLimit - this.pagination.itemLimit,
          take: this.pagination.itemLimit,
        });
      }
    },
    async previousPage() {
      if (this.pagination.currentPage <= 1) {
        this.$store.dispatch('addToastNotifications', {
          text: "you're on first page",
          type: 'error',
          timer: 2000,
        });
      } else {
        this.pagination.currentPage -= 1;
        this.pagination.firstItem -= this.pagination.itemLimit;
        this.pagination.lastItem -= this.pagination.itemLimit;
        this.jumptoPageNo = this.pagination.currentPage;
        this.$emit('changePage', {
          skip: this.pagination.currentPage * this.pagination.itemLimit - this.pagination.itemLimit,
          take: this.pagination.itemLimit,
        });
      }
    },
  },
};
</script>

<style>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
