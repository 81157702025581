<template>
  <div id="upload-immerse" class="about">
    <section class="md:w-1/2 p-4 mx-auto mb-8 bg-white rounded-lg shadow">
      <div class="flex items-center justify-between px-1">
        <h3 class="text-lg text-gray-800">Upload file on:</h3>
        <!-- eslint-disable-next-line prettier/prettier -->
        <Dropdown
          model-name="platform"
          badge-title="Platform"
          default-option="-- Select platform --"
          error-text="* Please select platform name"
          :is-error="platformError"
          :data-list="platformList"
        />
      </div>
    </section>
    <section class="md:w-1/2 p-6 mx-auto mb-10 bg-white rounded-lg shadow">
      <div class="flex items-center justify-between px-1 mb-5">
        <h3 class="text-lg text-gray-800">
          Upload files to: <b class="capitalize">{{ platform }}</b>
        </h3>
      </div>
      <ul class="border border-gray-200 divide-y divide-gray-200 rounded-md">
        <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm" :class="{ 'pointer-events-none': fileUploading }">
          Upload file:
          <div v-if="files.length" class="flex items-center flex-1 w-0 ml-2">
            <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
            </svg>
            <span class="flex items-center flex-1 w-0 ml-2 truncate">
              {{ files[files.length - 1].name | sanitizeText }}
              <XCircleIcon v-if="!fileUploading" size="1.2x" class="inline-block ml-2 text-gray-500 cursor-pointer" @click="files = []" />
            </span>
          </div>
          <div v-else class="relative flex items-center flex-1 w-0 ml-2 text-left">
            <input id="hidden-input" type="file" multiple class="hidden" />
            <file-upload
              :class="{ 'file-preview-active': files.length && files[0].thumb }"
              class="flex-1 w-0 ml-2 text-gray-400 truncate"
              :multiple="false"
              :directory="false"
              :size="size || 0"
              :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
              :drop="false"
              :drop-directory="false"
              :add-index="false"
              v-model="files"
              @input-filter="inputFilter"
              @input-file="inputFile"
              extensions="csv"
              accept="text/csv"
              ref="upload"
            >
              Select CSV...
            </file-upload>
          </div>

          <div v-if="files.length" class="flex-shrink-0 ml-4">
            <button :class="{ loading: fileUploading }" :disabled="fileUploading" class="hover:text-blue-700 button font-medium text-blue-600" @click="uploadFileToS3Func">
              Upload
              <ring-loader color="rgba(16, 185, 129)" />
            </button>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { XCircleIcon } from 'vue-feather-icons';
import FileUpload from 'vue-upload-component';

export default {
  name: 'UploadPage',
  components: {
    XCircleIcon,
    FileUpload,
  },
  data() {
    return {
      fileUploading: false,
      dataUploading: false,
      renderReady: false,
      filesUploaded: false,
      files: [],
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: false,
      directory: false,
      thread: 10,
      name: 'file',
      videoFile: null,
      videoFileName: '',
      platformError: false,
      platform: '',
      platformList: [
        {
          title: 'BMC',
          value: 'bmc',
        },
        {
          title: 'Non-BMC',
          value: 'non-bmc',
        },
        {
          title: 'Offcon',
          value: 'offcon',
        },
        // {
        //   title: 'Edcast',
        //   value: 'edcast',
        // },
        // {
        //   title: 'Transform',
        //   value: 'transform',
        // },
        // {
        //   title: 'Domo',
        //   value: 'domo',
        // },
        {
          title: 'User Group Mapping',
          value: 'user-group-mapping',
        },
        {
          title: 'Adjustment',
          value: 'adjustment',
        },
        {
          title: 'Special Program Master',
          value: 'spcl_program_master',
        },
        {
          title: 'BITS Wave Source',
          value: 'bits_wave_source',
        },
        {
          title: 'Penetration MSS',
          value: 'penetration_mss',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['request']),
  },
  methods: {
    ...mapActions(['uploadFileToS3', 'fetchQueryExecHistory']),
    async uploadFileToS3Func() {
      if (this.platform === '') {
        this.platformError = true;
        return;
      }
      try {
        this.fileUploading = true;
        const path = this.platform;
        const type = 'immerse';
        let file = null;
        if (this.files.length && this.files[0].file) file = [...this.files][0].file;
        await this.uploadFileToS3({ file, path, type });
        this.$store.dispatch('addToastNotifications', {
          text: 'File uploaded successfully',
          timer: 4000,
        });
      } catch (e) {
        console.warn(e);
        this.$store.dispatch('addToastNotifications', {
          text: `File upload failed with error: ${e.message}`,
          type: 'error',
          timer: 4000,
        });
      }
      this.files = [];
      this.fileUploading = false;
    },
    inputFilter(x, oldFile, prevent) {
      const newFile = x;
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = '';
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
      }
      return null;
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, { error: 'size' });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.file-uploads {
  text-align: left;
}
</style>
