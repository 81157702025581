<template>
  <section class="p-4 mx-auto mb-8 bg-white rounded-lg shadow">
    <div class="flex items-center justify-between px-1">
      <h3 class="text-lg text-blue-900 font-semibold my-2">Attached Policies ({{ (filteredSkillPolicies && filteredSkillPolicies.length) || 0 }})</h3>
      <div>
        <button @click="publishSkillPolicy(masterHierarchyId)" class="mx-2 py-1 px-4 text-sm text-white bg-genome-aqua-blue border border-genome-aqua-blue rounded" title="Publish skill policies">Publish</button>
        <button @click="fetchSkillPolicies(masterHierarchyId)" class="mx-2 py-1 px-4 text-sm text-blue-900 bg-white border border-blue-900 rounded" title="Refresh Table">Refresh Table</button>
      </div>
    </div>
    <div class="relative my-1 pt-4">
      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Search Policy</p>
      <input v-model="searchPolicy" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded h-full" type="text" placeholder="Search policy name..." />
    </div>
    <!-- <section class="flex items-center justify-between border-b border-gray-100">
      <p class="my-4 text-base">Total results: {{ (filteredSkillPolicies && filteredSkillPolicies.length) || 0 }}</p>
    </section> -->
    <table v-if="skillPolicies.length && !isLoadingPolicies" class="whitespace-nowrap w-full border-t-0 border-collapse">
      <thead class="text-gray-dark">
        <tr class="px-4 border-t border-b border-gray-100">
          <th v-for="(heading, index) in headings" :key="index">
            <p class="flex items-center h-10 px-4 text-xs font-normal text-left">
              <b>{{ heading }}</b>
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(skillPolicy, index) in filteredSkillPolicies"
          :key="index"
          v-show="changePageObj.skip <= index && index < changePageObj.skip + changePageObj.take"
          class="hover:bg-indigo-50 h-12 text-sm text-gray-800 px-4"
          :class="{ 'bg-main': index % 2 === 0 }"
        >
          <td class="w-full px-4">
            <div class="flex">
              <label class="truncate">{{ skillPolicy.Policies.name }}</label>
              <p v-if="!skillPolicy.status" class="bg-red-100 text-red-700 mx-1 px-2 rounded text-sm">Disabled</p>
            </div>
          </td>
          <td class="w-full px-4">
            <!-- eslint-disable-next-line prettier/prettier -->
            <toggle-button
              :modal-id="index"
              :toggle-val="skillPolicy.status"
              @toggleValReturn="(status) => ((skillPolicy.status = status), $emit('editSkillPolicy', { id: skillPolicy.id, status }))"
              :confirmation-modal="true"
              :modal-props="modalProps"
            />
          </td>
          <td class="flex w-full px-4 my-1.5">
            <button
              :class="{ 'text-green-600 border-green-500 bg-green-100': copyInterval && tempName === skillPolicy.Policies.name }"
              class="border file-grand-child-a-hover font-medium flex hover:shadow md:mr-2 p-2 py-auto leading-snug rounded-full outline-none focus:outline-none flex-wrap content-center"
              @click.prevent.stop="copyPolicyName(skillPolicy.Policies.name)"
              title="Copy policy name"
            >
              <LinkIcon class="text-gray-700 text-lg my-auto" size="1x" />
            </button>
            <button
              class="border file-grand-child-a-hover font-medium flex hover:shadow md:mr-2 p-2 py-auto leading-snug rounded-lg outline-none focus:outline-none flex-wrap content-center"
              @click="$emit('editSkillPolicyObj', skillPolicy)"
              title="Edit policy"
            >
              <EditIcon class="text-gray-700 text-lg my-auto" size="1x" />
            </button>
            <!-- <button
              @click="enableDisablePolicyFunc(policy.policyid, policy.status)"
              class="button h-8 px-4 rounded text-sm"
              :class="[policy.status ? 'bg-blue-500 text-white border border-blue-500' : 'bg-white text-blue-500 border', { loading: isEnDis && isId === policy.policyid }]"
            >
              {{ policy.status ? 'Disable' : 'Enable' }}
              <ring-loader class="mb-5" height="20px" width="20px" :color="policy.status ? 'rgb(255, 255, 255)' : 'rgb(59, 130, 246)'" />
            </button> -->
          </td>
        </tr>
        <tr>
          <td colspan="3" role="status" class="mx-auto py-10 h-72 text-center" v-if="!filteredSkillPolicies.length && !isLoadingPolicies">
            <h5 class="text-lg">No policy found...</h5>
            <p class="mb-5 text-xs">Please search with different keyword</p>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else-if="isLoadingPolicies" class="h-72 flex flex-col items-center justify-center text-center">
      <ring-loader class="mb-5" height="35px" width="35px" color="rgb(0, 173, 207)" />
      <h5 class="text-lg">Fetching policies...</h5>
      <p class="mb-5">Please be patient</p>
    </div>
    <div v-else class="h-72 flex flex-col items-center justify-center text-center">
      <h5 class="text-lg">No policy added</h5>
      <p class="mb-5 text-xs">Please add policy to continue...</p>
    </div>
    <Pagination
      v-if="filteredSkillPolicies && filteredSkillPolicies.length"
      :total-items="(filteredSkillPolicies && filteredSkillPolicies.length) || 0"
      :items-limit="50"
      :search-policy="searchPolicy"
      @changePage="(ab) => (changePageObj = ab)"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line object-curly-newline
import { LinkIcon, EditIcon } from 'vue-feather-icons';
import Pagination from '@/components/Pagination.vue';
import ToggleButton from './global/ToggleButton.vue';

export default {
  name: 'PolicyTable',
  components: {
    EditIcon,
    LinkIcon,
    Pagination,
    ToggleButton,
  },
  props: {
    masterHierarchyId: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      isId: '',
      searchPolicy: '',
      isLoadingPolicies: false,
      headings: ['Name', 'Status', 'Actions'],
      copyInterval: false,
      isEnDis: false,
      tempName: null,
      changePageObj: { skip: 0, take: 50 },
      modalProps: {
        title: 'Are you sure?',
        trueBody: 'Do you want to make this skill policy <b>inactive</b>?',
        falseBody: 'Do you want to make this skill policy <b>active</b>?',
        cancelBtnLabel: 'Cancel',
        confirmBtnLabel: 'Yes',
        selectedRows: [],
      },
    };
  },
  computed: {
    ...mapGetters(['skillPolicies']),
    filteredSkillPolicies() {
      return this.skillPolicies.filter((obj) => obj.Policies?.name?.toLowerCase()?.includes(this.searchPolicy?.toLowerCase()));
    },
  },
  async mounted() {
    await this.fetchSkillPolicies(this.masterHierarchyId);
  },
  methods: {
    ...mapActions(['getSkillPolicies', 'refreshSkillPolicy']),
    async publishSkillPolicy(id) {
      const data = await this.refreshSkillPolicy(id);
      if (data.includes('Request processing successfully')) {
        await this.$store.dispatch('addToastNotifications', {
          text: 'Skill policy published successfully!',
          type: 'success',
          timer: 3000,
        });
      }
    },
    async fetchSkillPolicies(masterHierarchyId) {
      this.isLoadingPolicies = true;
      await this.getSkillPolicies({
        masterHierarchyId: { equals: masterHierarchyId },
      });
      this.isLoadingPolicies = false;
    },
    // async enableDisablePolicyFunc(policyId, status) {
    //   console.log('policyId, status', policyId, status);
    //   const action = status ? 'disable' : 'enable';
    //   const type = status ? 'POLICY_DISABLE' : 'POLICY_ENABLE';
    //   const policy = {
    //     event_status: 'NEW',
    //     event_type: type,
    //     policyId,
    //     status: status ? 0 : 1,
    //     version: status ? 1 : 2,
    //   };
    //   this.isId = policyId;
    //   this.isEnDis = true;
    //   const res = await this.enableDisablePolicy({ action, policy });
    //   this.isEnDis = false;
    //   if (res && res.message === 'Processing your request') {
    //     this.$store.dispatch('addToastNotifications', {
    //       text: res.message,
    //       type: 'success',
    //       timer: 2000,
    //     });
    //   }
    // },
    // -------------- Copy Policy Name -------------------
    async copyPolicyName(name) {
      this.copyInterval = true;
      await this.$store.dispatch('addToastNotifications', {
        text: 'Policy name copied to clipboard.',
        type: 'success',
        timer: 3000,
      });
      if (this.tempName && this.tempName === name) return true;
      this.tempName = name;
      const tempInput = document.createElement('input');
      tempInput.value = `${name}`;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      setTimeout(() => {
        this.copyInterval = false;
        this.tempName = null;
      }, 3000);
      return true;
    },
  },
};
</script>
