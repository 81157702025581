import Axios from 'axios';

const { VUE_APP_BUILD } = process.env || 'uat';
Axios.defaults.baseURL = `https://api-router.azurefd.net/${VUE_APP_BUILD === 'prod' ? 'prod' : 'uat'}/genome`;
Axios.defaults.headers.common['x-oauth-config-provider'] = 'cognito-aps1';
// Axios.defaults.headers.common.Accept = 'application/json';

export default (Vue) => {
  Object.defineProperties(Vue.prototype, {
    $http: {
      get() {
        return Axios;
      },
    },
  });
};
