export const getSkillsQuery = `query {
  Group(groupId: "95e686e7-5651-4c92-8832-6dda6aec35d5") {
      id
      name
      Levels {
        id
        aka
        name
        code
      }
      Master {
        id
        name
        aka
        description
        code
        MasterHierarchy {
          id
          archetype
          parentId
          message
          mandateData
          status
          addToMyPlan
          KeywordsGroup {
            id
            Keywords{
              id
              name
              description
              status
              updatedAt
            }
          }
        }
      }
    }
  }`;

export const addSkillsQuery = `mutation KeywordsUpdate(
    $groupId: String
    $masterId: String!
    $name: String
    $description: String
    $archetype: String
    $mandateData: JSON
    $message: String
    $parentId: String
    $ohrId: Float!
    ) {
    KeywordsUpdate(
      groupId: $groupId
      ohrId: $ohrId
      masterId: $masterId
      # masterHierarchyId: "0f0a2e36-8521-4c9a-ab23-000a14d719e7"
      keywordUpdateInput: {
        # id: "6cbcbd38-296b-412d-9a65-a7c33bf0a0c7"
        name: $name
        description: $description
        status: true
        archetype: $archetype
        mandateData: $mandateData
        message: $message
        parentId: $parentId
      }
    ) {
      id
      name
      KeywordsGroup {
        id
        Keywords {
          id
          name
          description
          status
        }
      }
    }
  }`;

export const updateSkillsQuery = `mutation KeywordsUpdate(
    $groupId: String
    $masterId: String!
    $masterHierarchyId: String
    $skillId: ID
    $name: String
    $description: String
    $archetype: String
    $message: String
    $status: Boolean
    $addToMyPlan: Boolean
    $mandateData: JSON
    $ohrId: Float!
  ) {
    KeywordsUpdate(
      groupId: $groupId
      masterId: $masterId
      ohrId: $ohrId
      masterHierarchyId: $masterHierarchyId
      keywordUpdateInput: {
        id: $skillId
        name: $name
        description: $description
        status: $status
        archetype: $archetype
        message: $message
        addToMyPlan: $addToMyPlan
        mandateData: $mandateData
      }
    ) {
      id
      name
      KeywordsGroup {
        id
        Keywords {
          id
          name
          description
          status
        }
      }
    }
  }`;

export const getPoliciesQuery = `query {
  Policy {
    id
    code
    name
    aka
    type
    status
    isMandatory
    addTomyPlan
    proficientId
    policyCondition
    Levels {
      name
      title
    }
  }
}`;

export const createPolicyQuery = `mutation PoliciesUpdate($isMandatory: Boolean, $proficientId: String, $policyCondition: JSON, $groupId: String, $name: String) {
  PoliciesUpdate(
    policyInput: {
      data: [
        {
          name: $name
          policyCondition: $policyCondition
          isMandatory: $isMandatory
          proficientId: $proficientId
          status: true
          groupId: $groupId
        }
      ]
    }
  ) {
    message
  }
}`;

export const updatePolicyQuery = `mutation PoliciesUpdate($isMandatory: Boolean, $proficientId: String, $policyCondition: JSON, $groupId: String, $name: String, $id: String) {
  PoliciesUpdate(
    policyInput: {
      data: [
        {
          id: $id
          name: $name
          policyCondition: $policyCondition
          isMandatory: $isMandatory
          proficientId: $proficientId
          status: true
          groupId: $groupId
        }
      ]
    }
  ) {
    message
  }
}`;

export const updatePolicyStatusQuery = `mutation PoliciesUpdate($status: Boolean, $id: String) {
  PoliciesUpdate(
    policyInput: {
      data: [
        {
          id: $id
          status: $status
        }
      ]
    }
  ) {
    message
  }
}`;

export const getSkillPoliciesQuery = `query SkillPolicy($masterHierarchyId: UuidFilter){
  SkillPolicy(
    where: {
      masterHierarchyId: $masterHierarchyId
    }
  ) {
    id
    policyId
    status
    Policies{
      id
      name
      type
    }
    MasterHierarchy {
      KeywordsGroup {
        Keywords {
          name
        }
      }
    }
  }
}`;

export const updateSkillPoliciesQuery = `mutation SkillPoliciesUpdate($data: [SkillPolicyInputDetail!]!){
  SkillPoliciesUpdate(
    skillPolicyInput: {
      data: $data
    }
  ) {
    message
  }
}`;
