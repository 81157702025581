<template>
  <section class="flex flex-col items-center justify-center h-screen text-lg text-gray-700">
    <!-- <button @click="login">Open Okta</button> -->
    <!-- <img class="w-48 mb-3" src="@/assets/genome-header.svg" /> -->
    <p class="mb-3 text-2xl">Logged out</p>
    <p class="mb-20">You have been logged out of the application</p>
  </section>
</template>

<script>
export default {
  name: 'LogoutPage',
};
</script>
