import ActionModule from './skillPolicy-actions';

const initializeState = () => ({
  skillPolicies: [],
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setSkillPoliciesInStore(state, payload) {
      state.skillPolicies = payload || [];
    },
  },
  actions: ActionModule({ $http }),
  getters: {
    skillPolicies: (state) => state.skillPolicies,
  },
});
