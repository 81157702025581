import Moment from 'moment';

export default (Vue) => {
  Object.defineProperties(Vue.prototype, {
    $moment: {
      get() {
        return Moment;
      },
    },
  });
};
