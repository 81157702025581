<template>
  <main id="leftbar" class="shadow-pane bg-white min-h-full fixed sm:relative z-10">
    <div class="flex flex-col text-sm my-4">
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 1)"
        :to="{ name: 'Dashboard.Download' }"
        class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors"
        :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('Download') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Reports
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 2)"
        :to="{ name: 'Dashboard.Upload' }"
        class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors"
        :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('Upload') }"
      >
        <UploadCloudIcon size="1.3x" class="mr-2" />
        Upload files
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 3)"
        :to="{ name: 'Dashboard.Solidify' }"
        class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors"
        :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('Solidify') }"
      >
        <UploadCloudIcon size="1.3x" class="mr-2" />
        Solidify (Upload)
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 4)"
        :to="{ name: 'Dashboard.ReleaseManagement' }"
        class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors"
        :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('ReleaseManagement') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Release Management
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 5)"
        :to="{ name: 'Dashboard.Skills' }"
        class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors"
        :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('.Skill') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Skills
      </router-link>
      <router-link
        v-if="userPayload && userPayload.pages && userPayload.pages.some((obj) => obj.code === 6)"
        :to="{ name: 'Dashboard.Policies' }"
        class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors"
        :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('.Policies') }"
      >
        <FileTextIcon size="1.3x" class="mr-2" />
        Policies
      </router-link>
      <!-- <router-link :to="{ name: 'Dashboard.SkillPolicies' }" class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors" :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('RuleEngine') }">
        <FileTextIcon size="1.3x" class="mr-2" />
        Skill Policies
      </router-link> -->
      <!-- <router-link :to="{ name: 'Dashboard.LSReports' }" class="hover:bg-gray-100 flex items-center px-5 py-2 text-gray-500 transition-colors" :class="{ 'font-semibold text-brand-tertiary': $route.name.includes('RuleEngine') }">
        <FileTextIcon size="1.3x" class="mr-2" />
        LS Reports
      </router-link> -->
    </div>
  </main>
</template>

<script>
import { UploadCloudIcon, FileTextIcon /* , DownloadCloudIcon, SlidersIcon */ } from 'vue-feather-icons';

export default {
  name: 'TheSidebar',
  props: {
    navExpand: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadCloudIcon,
    FileTextIcon,
    // SlidersIcon,
    // DownloadCloudIcon,
  },
  data() {
    return {
      userPayload: JSON.parse(localStorage.getItem('rbac_user')),
    };
  },
};
</script>

<style scoped>
.shadow-pane {
  box-shadow: 0 3px 15px #41597117;
}

.slide-enter-active,
.slide-leave-active {
  transition: width 0.2s linear;
}

.slide-enter-from,
.slide-leave-to {
  width: 0;
}
</style>
