<template>
  <div class="fixed top-0 left-0 z-20 flex items-center justify-between w-full h-16 px-6 pl-2 bg-white shadow">
    <div class="flex items-center w-1/2">
      <div @click="$emit('toggle-nav')" class="relative flex flex-col items-center justify-center w-12 h-12 mr-5 text-xl text-white bg-blue-900 cursor-pointer">
        <hr class="bg-white h-0.5 transition-all my-0.5 w-6" :class="{ 'transform rotate-45 absolute': navExpand }" />
        <hr class="bg-white h-0.5 transition-all my-0.5 w-6" :class="{ 'transform -rotate-45 absolute': navExpand }" />
        <hr v-if="!navExpand" class="bg-white h-0.5 my-0.5 w-6" />
      </div>
      <img class="w-32" src="@/assets/genome-header.svg" />
    </div>
    <!-- <h2 v-else class="flex items-center mb-3 text-sm font-semibold text-gray-900">
        <span class="font-light">Workflow</span><img class="mx-1.5" src="@/assets/arrow-right.svg">PULSE
      </h2> -->
    <!-- <div class="w-72 relative flex items-center"> -->
    <!-- <input
          placeholder="Search"
          class="focus:ring-inset focus:ring-2 focus:outline-none block w-full px-4 py-2 pr-8 text-sm leading-tight text-gray-700 bg-white border border-gray-300 rounded appearance-none"
        >
        <img class="absolute top-0 bottom-0 right-0 my-auto mr-3" src="@/assets/search.svg"> -->
    <!-- </div> -->
    <div class="flex items-center justify-around">
      <!-- <img class="h-6" src="@/assets/icons/settings.svg" /> -->
      <!-- <img class="h-6 ml-8" src="@/assets/icons/notification.svg" /> -->

      <div @click="showLogOut = !showLogOut" class="relative">
        <div class="text-brand-secondary flex items-center mx-2 text-sm tracking-wide cursor-pointer">
          <div class="capitalize">
            <p class="pb-1 font-semibold leading-tight truncate">{{ user.fullName }}</p>
          </div>
          <i class="fas fa-chevron-down text-brand-secondary ml-2 text-xs"></i>
        </div>
        <button v-if="showLogOut" class="ring-1 ring-black ring-opacity-5 hover:bg-gray-100 absolute top-0 right-0 flex items-center w-32 px-3 py-2 mt-10 text-sm text-gray-700 bg-white rounded-md shadow-lg" @click="logout('/signout')">
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheHeader',
  props: {
    navExpand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLogOut: false,
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['logout']),
  },
};
</script>
