import Vue from 'vue';
import VueRouter from 'vue-router';
// import { Auth } from 'aws-amplify';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard.Download',
        component: () => import(/* webpackChunkName: "dashboard.download" */ '@/views/Download.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/learner-score-reports',
        name: 'Dashboard.LSReports',
        component: () => import(/* webpackChunkName: "dashboard.download" */ '@/views/LSReports.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/upload',
        name: 'Dashboard.Upload',
        component: () => import(/* webpackChunkName: "dashboard.upload" */ '@/views/Upload.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/upload-solidify',
        name: 'Dashboard.Solidify',
        component: () => import(/* webpackChunkName: "dashboard.upload" */ '@/views/UploadSolidify.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/skills',
        name: 'Dashboard.Skills',
        component: () => import(/* webpackChunkName: "dashboard.RuleEngine" */ '@/views/Skills.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/policies',
        name: 'Dashboard.Policies',
        component: () => import(/* webpackChunkName: "dashboard.RuleEngine" */ '@/views/Policies.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/skill-policies/:mhId',
        name: 'Dashboard.Skills.SkillPolicies',
        component: () => import(/* webpackChunkName: "dashboard.download" */ '@/views/SkillPolicies.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/release-management',
        name: 'Dashboard.ReleaseManagement',
        component: () => import(/* webpackChunkName: "dashboard.download" */ '@/views/ReleaseManagement.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/login',
    name: 'Auth.Login',
    component: () => import(/* webpackChunkName: "auth.login" */ '@/views/Login.vue'),
  },
  {
    path: '/signout',
    name: 'Auth.Logout',
    component: () => import(/* webpackChunkName: "auth.logout" */ '@/views/Logout.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const createRouter = ({ store }) => {
  const router = new VueRouter({
    routes,
    mode: 'history',
    fallback: false,
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
      if (from.meta.saveScrollPos) {
        store.dispatch('setViewScrollPos', {
          name: from.name,
          coordinates: {
            x: window.scrollX,
            y: window.scrollY,
          },
        });
      }
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
  });

  // router.beforeResolve((to, from, next) => {
  //   if (to.matched.some((record) => record.meta.requiresAuth)) {
  //     Auth.currentAuthenticatedUser()
  //       .then(async () => {
  //         if (!store.getters.isLoggedIn) await store.dispatch('getAmplifySession');
  //         next();
  //       })
  //       .catch((err) => {
  //         console.warn(err);
  //         next({
  //           path: '/login',
  //         });
  //       });
  //   }
  //   next();
  // });

  router.afterEach(() => {
    // store.dispatch('clearViewScrollPos', to.name);
    // @ts-ignore
    if (store.state.router.next) store.dispatch('clearNextRoute');
  });

  return router;
};

export default createRouter;
