<template>
  <main id="tasks">
    <div class="pt-5 mt-5 bg-white rounded-lg shadow-md">
      <section class="flex items-center justify-between px-4 pb-4 border-b border-gray-100">
        <h3 class="text-2xl text-blue-900 font-semibold">Genome</h3>
        <button
          :class="{ loading: request.keys.includes('fetchLibraries') && request.inProgress }"
          :disabled="request.keys.includes('fetchLibraries') && request.inProgress"
          class="button focus:outline-none hover:text-gray-700 hover:border-gray-500 flex items-center px-3 ml-4 text-xs font-medium leading-6 text-gray-600 border border-gray-400 rounded-lg cursor-pointer"
          @click="getLibraries()"
        >
          <RefreshCwIcon size="1x" class="mr-2" /> Refresh
          <ring-loader color="rgba(59, 130, 246, 1)" width="20px" height="20px" />
        </button>
      </section>
      <section class="w-full mx-auto overflow-x-auto">
        <table v-if="!request.keys.includes('fetchLibraries') && !request.keys.includes('fetchVersions')" class="whitespace-nowrap w-full border-t-0 border-collapse">
          <thead class="text-gray-dark">
            <tr class="">
              <th v-for="column in columnDefs" :key="column.heading">
                <p class="flex items-center h-10 px-4 pr-10 text-xs font-normal text-left">
                  {{ column.heading }}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, index) in libraries" :key="index" class="h-12 text-sm text-gray-800 hover:bg-indigo-50" :class="{ 'bg-main': index % 2 === 0 }">
              <td v-for="column in columnDefs" :key="column.heading" :class="[column.field === 'status' && result[column.field] === 4 ? 'w-10' : '']">
                <div class="relative flex items-center px-4">
                  <div v-if="column.field === 'tag'" class="w-40 m-4">
                    <div class="relative">
                      <select
                        :id="'tag' + index"
                        @change="selectedTag(index, result.library)"
                        class="focus:outline-none block w-full h-full pl-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none cursor-pointer capitalize"
                      >
                        <option v-for="(opt, indexx) in Object.keys(result.tags) || []" :key="indexx" :value="opt" :selected="result.version.includes(opt)" class="capitalize">
                          {{ opt }}
                        </option>
                      </select>
                      <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="column.field === 'versions'" class="w-40 m-4">
                    <div class="relative">
                      <select :id="'versions' + index" class="focus:outline-none block w-full h-full pl-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none cursor-pointer">
                        <option disabled selected value="">-- select --</option>
                        <option v-for="(opt, indexx) in result.selectedVers.length ? result.selectedVers : []" :key="indexx" :value="opt" :selected="result.tags[result.version] === opt" class="capitalize">
                          {{ opt }}
                        </option>
                      </select>
                      <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <button
                    v-else-if="column.field === 'action'"
                    :class="{ loading: request.keys.includes('putVersion') && request.inProgress && index === idx }"
                    class="button focus:outline-none hover:text-gray-700 hover:border-gray-500 flex items-center px-3 ml-4 text-xs font-medium leading-6 text-gray-600 border border-gray-400 rounded-lg cursor-pointer"
                    @click="updateVersionFunc(index, result.library)"
                  >
                    Update
                    <ring-loader color="rgba(59, 130, 246, 1)" width="20px" height="20px" />
                  </button>
                  <span v-else-if="column.field === 'version'" class="flex flex-col">
                    <span>{{ result[column.field] }}</span>
                    <i class="text-xs">({{ result.tags[result[column.field]] }})</i>
                  </span>
                  <span v-else-if="column.field === 'updated' || column.field === 'created'">{{ result[column.field] | dateFormat }}</span>
                  <span v-else>{{ result[column.field] ? result[column.field] : '--' }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="flex flex-col items-center justify-center text-center h-72">
          <ring-loader class="mb-5" height="35px" width="35px" color="rgb(0, 173, 207)" />
          <h5 class="text-lg">Fetching libraries...</h5>
          <p class="mb-5">Please be patient</p>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RefreshCwIcon } from 'vue-feather-icons';

export default {
  name: 'ReleaseManagement',
  components: {
    RefreshCwIcon,
  },
  props: {},
  data() {
    return {
      idx: null,
      versionDD: [],
      dispVesion: '',
      columnDefs: [
        {
          field: 'version',
          heading: 'Version',
        },
        {
          field: 'library',
          heading: 'Library',
        },
        {
          field: 'cdn',
          heading: 'CDN',
        },
        {
          field: 'source',
          heading: 'Source',
        },
        {
          field: 'status',
          heading: 'Status',
        },
        {
          field: 'updated',
          heading: 'Updated',
        },
        {
          field: 'tag',
          heading: 'Tag',
        },
        {
          field: 'versions',
          heading: 'Versions',
        },
        {
          field: 'action',
          heading: 'Action',
        },
      ],
    };
  },
  mounted() {
    this.getLibraries();
  },
  computed: {
    ...mapGetters(['versions', 'libraries', 'request']),
  },
  methods: {
    ...mapActions(['getLibraries', 'updateVersion']),
    selectedTag(id, library) {
      const tag = document.getElementById(`tag${id}`).value;
      this.$store.commit('changeSelectedTag', { library, tag });
    },
    async updateVersionFunc(id, library) {
      const updatedVersion = document.getElementById(`versions${id}`).value;
      if (!updatedVersion.length) {
        this.$store.dispatch('addToastNotifications', {
          text: `Please select version of "${library}"`,
          type: 'error',
          timer: 4000,
        });
        return;
      }
      this.idx = id;
      await this.updateVersion({ updatedVersion, library });
    },
  },
};
</script>
<style scoped>
table,
td {
  border: 0.5px solid rgb(202 202 202 / 30%);
}
</style>
