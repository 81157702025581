<template>
  <section class="RuleEngine">
    <!-- <h1 class="text-xl text-genome-dark-blue font-bold mb-4">Policies</h1> -->
    <section class="p-4 mx-auto mb-8 bg-white rounded-lg shadow">
      <div class="flex items-center justify-between px-1">
        <span class="flex items-center">
          <router-link :to="{ name: 'Dashboard.Skills' }" class="p-1 rounded-full shadow-lg">
            <!-- eslint-disable-next-line vue/max-attributes-per-line -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left">
              <line x1="19" y1="12" x2="5" y2="12" />
              <polyline points="12 19 5 12 12 5" />
            </svg>
          </router-link>
          <h3 v-if="skillObj && skillObj.KeywordsGroup" class="text-lg text-genome-dark-blue font-semibold mx-4">{{ skillObj.KeywordsGroup.Keywords.name }}</h3>
        </span>
        <button
          v-if="Object.keys(skillObj).length"
          @click="(isOpenWindow = skillObj && skillObj.KeywordsGroup ? !isOpenWindow : isOpenWindow), (searchPolicy = ''), (selectedSkillPolicy = {})"
          class="py-2 px-4 text-sm text-white bg-genome-dark-blue rounded"
        >
          Attach new policy
        </button>
      </div>
    </section>
    <div v-if="isOpenWindow">
      <section class="p-4 mx-auto mb-8 bg-white rounded-lg shadow relative">
        <h3 class="text-lg text-gray-800 font-semibold border-b pb-2 mb-2">{{ selectedSkillPolicy.id ? 'Update skill policy' : 'Create new skill policy' }}</h3>
        <p class="bg-white py-1 rounded shadow-custom text-sm flex items-center">
          <span>Select policy to attach with {{ selectedSkillPolicy.id ? 'Skill Policy' : 'Skill' }}:</span>
          <span class="font-semibold mx-1">{{ selectedSkillPolicy.id ? selectedSkillPolicy.Policies.name : skillObj.KeywordsGroup.Keywords.name }}</span>
        </p>
        <!-- <div class="relative my-1 pt-4">
          <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Skill name</p>
          <h3 class="text-lg text-gray-800 border rounded-lg px-4 py-1 w-max">{{ $route.params.skillName || 'No skill name changes' }}</h3>
        </div>
        <h3 class="text-2xl text-genome-dark-blue font-semibold m-2">+</h3> -->
        <div class="relative my-1 pt-4 flex">
          <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Search Policy</p>
          <input v-model="searchPolicy" class="text-sm focus:outline-none text-gray-800 border rounded-lg w-full px-4 py-2" @focus="isEditKeyword = true" type="text" placeholder="Enter policy name..." />
          <XIcon v-if="searchPolicy.length" @click="(selectedPolicy = {}), (searchPolicy = ''), (isEditKeyword = false)" class="mx-2 my-auto text-gray-400 cursor-pointer" title="Remove Policy" />
        </div>
        <div v-if="isEditKeyword" class="">
          <div v-if="filteredPolicies.length" class="w-auto bg-white absolute h-32 overflow-y-auto">
            <span
              v-for="(policy, index) in filteredPolicies"
              :key="index"
              @click="(selectedPolicy = policy), (isEditKeyword = false), (searchPolicy = policy.name)"
              class="flex hover:bg-indigo-50 h-8 w-full text-sm text-gray-800 border cursor-pointer"
              :class="{ 'bg-main': index % 2 === 0 }"
            >
              <p class="my-auto px-2">{{ policy.name }}</p>
            </span>
          </div>
          <div v-else class="w-auto bg-white absolute">
            <span class="flex hover:bg-indigo-50 h-8 w-full text-sm text-gray-800 border cursor-pointer">
              <p class="my-auto px-2">No policy found...</p>
            </span>
          </div>
        </div>
        <!-- {{ skillObj }} -->
        <div class="flex justify-end mt-8">
          <button @click="clearForm()" class="relative button h-8 px-4 mx-4 rounded text-sm bg-white text-genome-dark-blue border border-genome-dark-blue">Cancel</button>
          <button
            @click="updateSkillPolicyFunc(selectedSkillPolicy && selectedSkillPolicy.id ? selectedSkillPolicy.id : null)"
            :disabled="!$route.params.mhId || !Object.keys(selectedPolicy).length"
            :class="{ loading: isCreateNewSkillPolicy }"
            class="relative button h-8 px-4 rounded text-sm bg-genome-dark-blue text-white border border-genome-dark-blue"
          >
            {{ selectedSkillPolicy && selectedSkillPolicy.id ? 'Update' : 'Create' }}
            <ring-loader class="mb-5" height="20px" width="20px" color="rgb(255, 255, 255)" />
          </button>
        </div>
      </section>
    </div>
    <SkillPolicyTable :master-hierarchy-id="$route.params.mhId" @editSkillPolicyObj="editSkillPolicyObj" @editSkillPolicy="updateSkillPolicyStatus" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SkillPolicyTable from '@/components/SkillPolicyTable.vue';
import { XIcon } from 'vue-feather-icons';

export default {
  name: 'RuleEngine',
  components: {
    SkillPolicyTable,
    XIcon,
  },
  data() {
    return {
      isCreateNewSkillPolicy: false,
      isEditKeyword: false,
      isOpenWindow: false,
      searchPolicy: '',
      skillObj: {},
      selectedPolicy: {},
      selectedSkillPolicy: {},
    };
  },
  watch: {
    skillsByMaster(val) {
      const { mhId } = this.$route.params;
      val?.map((obj) => {
        const aws = obj?.MasterHierarchy?.find((objj) => objj.id === mhId);
        if (aws) this.skillObj = aws;
        return aws;
      });
    },
  },
  computed: {
    ...mapGetters(['policies', 'skillsByMaster']),
    filteredPolicies() {
      return this.policies.filter((obj) => obj.name?.toLowerCase()?.includes(this.searchPolicy?.toLowerCase()));
    },
  },
  async beforeMount() {
    const userPayload = JSON.parse(localStorage.getItem('rbac_user'));
    if (userPayload && userPayload.pages && !userPayload.pages.some((obj) => obj.code === 101)) this.$router.push('/');
  },
  async mounted() {
    await this.getSkills();
    await this.getPolicies();
  },
  methods: {
    ...mapActions(['getPolicies', 'getSkills', 'updateSkillPolicy']),
    async clearForm() {
      this.isOpenWindow = false;
      this.isEditKeyword = false;
      this.searchPolicy = '';
      this.selectedPolicy = {};
      this.selectedSkillPolicy = {};
    },
    editSkillPolicyObj(skillPolicy) {
      // alert(JSON.stringify(skillPolicy));
      this.selectedSkillPolicy = skillPolicy;
      this.isOpenWindow = true;
      this.selectedPolicy = skillPolicy?.Policies || {};
      if (this.selectedPolicy?.name) this.searchPolicy = this.selectedPolicy?.name;
    },
    async updateSkillPolicyFunc(id) {
      let variables = {};
      const policyId = (this.selectedPolicy && this.selectedPolicy.id) || null;
      const masterHierarchyId = this.$route.params.mhId || null;
      if (id) variables = { ...variables, id };
      if (policyId) variables = { ...variables, policyId };
      if (masterHierarchyId) variables = { ...variables, masterHierarchyId };
      this.updtSkillPlc(variables);
    },
    async updateSkillPolicyStatus({ id, status }) {
      this.updtSkillPlc({ id, status });
    },
    async updtSkillPlc(variables) {
      this.isCreateNewSkillPolicy = true;
      const resp = await this.updateSkillPolicy({ data: [variables] });
      this.isCreateNewSkillPolicy = false;
      if (resp && resp.message) {
        this.$store.dispatch('addToastNotifications', {
          text: resp.message,
          type: 'success',
          timer: 3000,
        });
        this.clearForm();
      }
    },
  },
};
</script>
