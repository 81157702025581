<template>
  <main id="tasks">
    <div class="pt-5 mt-5 bg-white rounded-lg shadow-md">
      <section class="flex items-center justify-between px-4 pb-4 border-b border-gray-100">
        <h3 class="text-lg text-gray-800">
          Showing all
          <span v-if="filters.report" class="pb-1 text-blue-500 border-b border-blue-400">{{ filters.report.split('_').join(' ') }}</span>
          reports generated
          <span v-if="!filters.toDate && !filters.fromDate">till today</span>
          <span v-else>
            from <span class="pb-1 text-blue-500 border-b border-blue-400">{{ filters.fromDate | dateFormat }}</span> to
            <span class="pb-1 text-blue-500 border-b border-blue-400">{{ filters.toDate | dateFormat }}</span>
          </span>
          <button
            v-if="filters.toDate || filters.fromDate || filters.report"
            class="focus:outline-none hover:text-red-700 hover:border-red-400 inline-block px-3 ml-4 text-xs font-medium leading-6 text-red-600 border border-red-300 rounded-lg cursor-pointer"
            @click="$emit('clearFilters')"
          >
            Clear filters
          </button>
        </h3>
        <button
          v-if="inProgressExecutions.length"
          :class="{ loading: request.keys.includes('fetchQueryExecHistory') && request.inProgress }"
          :disabled="request.keys.includes('fetchQueryExecHistory') && request.inProgress"
          class="button focus:outline-none hover:text-gray-700 hover:border-gray-500 flex items-center px-3 ml-4 text-xs font-medium leading-6 text-gray-600 border border-gray-400 rounded-lg cursor-pointer"
          @click="$emit('refreshTableData')"
        >
          <RefreshCwIcon size="1x" class="mr-2" />Refresh reports
          <ring-loader color="rgba(59, 130, 246, 1)" width="20px" height="20px" />
        </button>
      </section>
      <section class="w-full mx-auto overflow-x-auto">
        <table v-if="executions.length" class="whitespace-nowrap w-full border-t-0 border-collapse">
          <thead class="text-gray-dark">
            <tr class="">
              <th v-for="column in columnDefs" :key="column.heading">
                <p class="flex items-center h-10 px-4 pr-10 text-xs font-normal text-left">
                  {{ column.heading }}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, index) in executions" :key="index" class="hover:bg-indigo-50 h-12 text-sm text-gray-800" :class="{ 'bg-main': index % 2 === 0 }">
              <td v-for="column in columnDefs" :key="column.heading" :class="[column.field === 'status' && result[column.field] === 4 ? 'w-10' : '']" class="max-w-lg">
                <p class="relative flex items-center px-4 capitalize">
                  <span v-if="column.field === 'report' && 'program' in result && result.program !== ''">Spcl Program ({{ result.program }})</span>
                  <span v-else-if="column.field === 'report'">{{ result[column.field].split('_').join(' ') }}</span>
                  <span v-else-if="column.field === 'status' && result[column.field] === 4" class="relative flex items-center justify-center w-full">
                    <ring-loader
                      v-if="request.inProgress && (request.keys.includes('downloadFileFromS3') || request.keys.includes('regenerateReport')) && fileProcessingId === result.queryexecutionid"
                      color="rgba(59, 130, 246, 1)"
                      width="20px"
                      height="20px"
                    />
                    <span v-else class="flex items-center justify-center w-full space-x-2">
                      <button
                        class="hover:text-blue-500 hover:border-blue-500 focus:outline-none px-2 py-1 text-sm text-gray-500 transition-colors bg-white border border-gray-300 rounded-md outline-none"
                        title="Download report"
                        @click="downloadReport(result)"
                      >
                        <DownloadCloudIcon size="1.2x" />
                      </button>
                      <button
                        class="hover:text-blue-500 hover:border-blue-500 focus:outline-none px-2 py-1 text-sm text-gray-500 transition-colors bg-white border border-gray-300 rounded-md outline-none"
                        title="Regenerate report"
                        @click="regenerateReportFunc(result)"
                      >
                        <RepeatIcon size="1.2x" />
                      </button>
                    </span>
                  </span>
                  <span v-else-if="column.field === 'status' && result[column.field] !== 4" :title="result.message" class="capitalize cursor-default">
                    {{ statusMapping[result[column.field]].split('_').join(' ') || result[column.field] | lowercase }}
                    <button
                      class="hover:text-blue-500 hover:border-blue-500 focus:outline-none px-2 py-1 ml-2 text-sm text-gray-500 transition-colors bg-white border border-gray-300 rounded-md outline-none"
                      title="Regenerate report"
                      v-if="result[column.field] === 5"
                      @click="regenerateReportFunc(result)"
                    >
                      <RepeatIcon size="1.2x" />
                    </button>
                  </span>
                  <span v-else-if="column.field === 'fromDate' || column.field === 'toDate'">{{ result[column.field] | dateFormat }}</span>
                  <span v-else-if="column.field === 'lastupdated'" class="normal-case">{{ result[column.field] | dateFormat }} at {{ result[column.field] | dateFormat({ timestampMini: true }) }}</span>
                  <span v-else-if="column.field === 'userId'" v-html="result[column.field] ? sanitizeText(result[column.field]) : `All OHR ID's`"></span>
                  <span v-else>{{ result[column.field] ? result[column.field] : '--' }}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else-if="request.inProgress && request.keys.includes('fetchQueryExecHistory')" class="h-72 flex flex-col items-center justify-center text-center">
          <ring-loader class="mb-5" height="35px" width="35px" color="rgb(0, 173, 207)" />
          <h5 class="text-lg">Fetching reports...</h5>
          <p class="mb-5">Please be patient</p>
        </div>

        <div v-else class="h-72 flex flex-col items-center justify-center text-center">
          <i class="icon os-icon os-icon-robot-1" />
          <h5 class="text-lg">No reports for selected filters</h5>
          <p class="mb-5">Try changing the filter criteria <span v-if="filters.report">or generate a new report</span></p>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RefreshCwIcon, DownloadCloudIcon, RepeatIcon } from 'vue-feather-icons';

const STATUS_MAPPINGS = {
  1: 'EXECUTION_STARTED',
  2: 'QUEUED',
  3: 'PROCESSING',
  4: 'COMPLETED',
  5: 'ERROR',
  // 1: 'PROCESSING',
  // 2: 'COMPLETED',
  // 3: 'ERROR',
};

export default {
  name: 'ExecutionTable',
  components: {
    RefreshCwIcon,
    DownloadCloudIcon,
    RepeatIcon,
  },
  props: {
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileProcessingId: null,
      fileReGeneratingId: null,
      columnDefs: [
        {
          field: 'report',
          heading: 'Report',
        },
        {
          field: 'fromDate',
          heading: 'Start date',
        },
        {
          field: 'toDate',
          heading: 'End date',
        },
        {
          field: 'ohrid',
          heading: 'Executed by',
        },
        {
          field: 'userId',
          heading: 'Executed for',
        },
        {
          field: 'lastupdated',
          heading: 'Execution time',
        },
        {
          field: 'status',
          heading: 'Status',
        },
      ],
      statusMapping: STATUS_MAPPINGS,
    };
  },
  computed: {
    ...mapGetters(['executions', 'request']),
    inProgressExecutions() {
      return this.executions.filter((x) => x.status <= 3);
    },
  },
  methods: {
    ...mapActions(['downloadFileFromS3', 'regenerateReport']),
    async downloadReport({ s3location, queryexecutionid }) {
      this.fileProcessingId = queryexecutionid;
      try {
        const file = queryexecutionid + s3location.split(queryexecutionid).pop();
        const bucketName = s3location.match(/(?<=\/)(.*?)(?=\/)/gi).pop();
        const signedUrl = await this.downloadFileFromS3({ bucketName, file });
        const link = document.createElement('a');
        link.href = signedUrl;
        link.click();
      } catch (err) {
        console.warn(err);
        this.$store.dispatch('addToastNotifications', {
          text: `File download failed with error: ${err.message}`,
          type: 'error',
          timer: 4000,
        });
      }
      this.fileProcessingId = null;
    },
    async regenerateReportFunc({ queryexecutionid, id, timestamp }) {
      this.fileProcessingId = queryexecutionid;
      try {
        await this.regenerateReport({ id, timestamp });
        this.$emit('refreshTableData');
      } catch (err) {
        console.warn(err);
      }
      this.fileProcessingId = null;
    },
    sanitizeText(val) {
      const newVal = val.toString();
      const splitedArr = newVal.split(',');
      return newVal.length < 30 ? newVal : `${splitedArr[0]},${splitedArr[1]},${splitedArr[2]} ... <span class="font-semibold px-2 py-0.5 rounded-full bg-gray-100">+${splitedArr.length - 3}</span>`;
    },
  },
};
</script>
<style scoped>
table,
td {
  border: 0.5px solid rgb(202 202 202 / 30%);
}
</style>
