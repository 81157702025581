<template>
  <div>
    <div class="search-cont relative flex">
      <input
        :id="id"
        :ref="id"
        v-model="searchQuery"
        :class="[
          withSearchButton ? 'border-r-0 rounded-r-none pl-4 pr-8' : 'pl-10 pr-4',
          inputClass
            ? inputClass
            : `
          appearance-none
          block
          w-full
          border
          border-gray-400
          rounded
          py-2
          leading-tight
          focus:outline-none
          bg-gray-100
          text-gray-600
          focus:bg-white
          focus:border-gray-500
      `,
        ]"
        type="search"
        :placeholder="placeholder"
        autocomplete="off"
        spellcheck="false"
        @input="inputChanged"
        @change="$emit('inputChange', searchQuery)"
      />
      <button v-if="!withSearchButton" class="absolute left-0 inset-y-0 pr-3 mb-1 flex items-center text-gray-600 outline-none focus:outline-none">
        <SearchIcon size="1.2x" class="text-gray-800" />
      </button>
      <transition name="search-close-fade">
        <button v-if="searchQuery" class="absolute flex items-center right-0 inset-y-0 px-3 text-gray-600" :class="{ 'mr-10': withSearchButton }" @click="clearSearch">
          <XCircleIcon size="1x" />
          <transition name="search-close-fade">
            <ring-loader v-show="dataLoading" class="absolute pin-abs-center" thickness="2px" width="20px" height="20px" />
          </transition>
        </button>
      </transition>
      <button v-if="withSearchButton" class="px-3 flex items-center text-white bg-brand-color border-red-600 border rounded-r-lg" @click.prevent="$emit('inputChange', searchQuery)">
        <XCircleIcon size="1x" />
      </button>
    </div>
    <span v-if="!isTwoChar" class="text-sm mb-2 text-gray-500"><i>Enter at least 2 characters for search</i></span>
    <div v-if="errorMsg !== ''" class="flex-col">
      <div class="text-lg mt-8 capitalize">
        <b>{{ myChangedTabName }}</b>
      </div>
      <span class="text-sm mb-2 text-gray-500">{{ errorMsg }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SearchIcon, XCircleIcon } from 'vue-feather-icons';

export default {
  name: 'SearchBar',
  components: {
    SearchIcon,
    XCircleIcon,
  },
  props: {
    changedTabName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    withSearchButton: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    autofocusTime: {
      type: Number,
      default: 0,
    },
    inputClass: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Quick search',
    },
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchQuery: '',
      myChangedTabName: 'personal files',
      dataLoading: false,
      isTwoChar: false,
      errorMsg: '',
    };
  },
  watch: {
    changedTabName(value) {
      this.searchQuery = '';
      this.errorMsg = '';
      this.$refs[this.id].focus();
      this.myChangedTabName = value;
    },
    value(value) {
      this.searchQuery = value;
    },
    loading(value) {
      this.dataLoading = value;
    },
  },
  mounted() {
    this.searchQuery = this.text;
    this.dataLoading = this.loading;
    setTimeout(() => {
      if (this.autofocus) this.$refs[this.id].focus();
    }, this.autofocusTime);
  },
  methods: {
    ...mapActions(['searchUsers', 'searchFileDir', 'clearSearchResults', 'addToastNotifications']),
    async inputChanged() {
      const reg = /[@!$%^&*()+|~=`\\#{}[\]:";'<>?,/]/g;
      this.errorMsg = '';
      this.isTwoChar = false;
      if (!this.searchQuery.match(reg)) {
        this.$emit('inputUpdate', this.searchQuery);
        if (this.searchQuery && this.searchQuery.length >= 2) {
          this.isTwoChar = true;
          this.dataLoading = true;
          try {
            if (this.myChangedTabName === 'users') {
              const searchedResult = await this.searchUsers({
                variables: {
                  keyword: this.searchQuery,
                  limit: 10,
                },
              });
              // console.log('searchedResult', searchedResult);
              if (!searchedResult.length) {
                this.errorMsg = 'No search result found!';
              }
            } else {
              const searchedResult = await this.searchFileDir({
                variables: {
                  keyword: this.searchQuery,
                  limit: 10,
                  drive: this.myChangedTabName.split(' ')[0],
                },
              });
              // console.log('searchedResult', searchedResult);
              if (!searchedResult.length) {
                this.errorMsg = 'No search result found!';
              }
            }
          } catch (error) {
            console.warn(error);
          }
          this.dataLoading = false;
        } else this.clearSearchResults();
      } else {
        this.addToastNotifications({
          text: 'Special characters are not allowed in search',
          type: 'error',
          timer: 2000,
        });
      }
    },
    clearSearch() {
      this.searchQuery = '';
      this.$emit('inputUpdate', '');
      if (this.autofocus) this.$refs[this.id].focus();
    },
  },
};
</script>

<style lang="postcss" scoped>
/* stylelint-disable */
.search-close-fade-enter-active {
  animation: fade 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.search-close-fade-leave-active {
  animation: fade 0.1s ease-out reverse;
}

input.hide-clear[type='search']::-ms-clear,
input.hide-clear[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-clear {
  display: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration,
input[type='search']::-webkit-search-decoration:focus,
input[type='search']::-webkit-search-cancel-button:focus,
input[type='search']::-webkit-search-results-button:focus,
input[type='search']::-webkit-search-results-decoration:focus,
input[type='search']::-webkit-search-decoration:hover,
input[type='search']::-webkit-search-cancel-button:hover,
input[type='search']::-webkit-search-results-button:hover,
input[type='search']::-webkit-search-results-decoration:hover {
  -webkit-appearance: none;
}
</style>
