<template>
  <div class="RuleEngine">
    <section class="mx-auto mb-8 mt-4 bg-white rounded-lg shadow">
      <div class="flex items-center justify-between">
        <h3 class="text-lg text-gray-800 font-semibold mx-4 my-5">Master Wise Skill List:</h3>
        <div class="flex m-4 items-center">
          <div v-if="skillsByMaster.length" class="relative">
            <select id="master" v-model="selectedMaster" class="focus:outline-none block w-full h-full pl-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none">
              <option disabled selected :value="{}">-- select master --</option>
              <option v-for="(opt, indexx) in skillsByMaster" :key="indexx" :value="opt.id" class="capitalize">
                {{ opt.description }}
              </option>
            </select>
            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
              <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <!-- <span id="err" class="text-red-500 text-xs hidden">*Please select</span> -->
          <button v-if="selectedMaster" @click="addNewSkill" class="flex items-center ml-4 px-2 py-1.5 rounded text-sm bg-genome-dark-blue text-white border border-genome-dark-blue">
            <PlusIcon size="1.2x" class="" />
            {{ selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? 'Add New Function' : 'Add New Skill' }}
          </button>
          <modal-comp ref="add-update-skill-popup" name="add-update-skill-popup" :show-close-btn="true">
            <div class="p-4">
              <h1 class="p-4 border-b text-xl font-semibold text-genome-dark-blue">{{ selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? modalTitle.replace('Skill', funcRole).replace(`Sub-${funcRole}`, 'Role') : modalTitle }}</h1>
              <div class="md:flex text-genpact-text-grey text-left mt-4">
                <div class="relative w-full">
                  <div class="m-4">
                    <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">
                      {{ selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? funcRole : editType !== 'sub-Skill' && editType !== 'create-sub-skill' ? 'Skill' : 'Sub-Skill' }} Name
                    </p>
                    <input
                      v-model="skillName"
                      class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded h-full w-full"
                      type="text"
                      :placeholder="`Enter ${selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? funcRole : editType !== 'sub-Skill' && editType !== 'create-sub-skill' ? 'Skill' : 'Sub-Skill'} name...`"
                    />
                  </div>
                </div>
                <div v-if="editType !== 'sub-Skill' && editType !== 'create-sub-skill' && selectedMaster !== '074cdf81-f882-4196-8bee-78e94c22724c'" class="relative w-full">
                  <SingleSelect model-name="archetype" badge-title="Archetype" :default-option="archetypeDef" :data-list="archeTypeList" @selectedOption="(val) => (archetype = val)" />
                </div>
              </div>
              <div v-if="editType !== 'sub-Skill' && editType !== 'create-sub-skill'" class="relative p-4 w-full">
                <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">
                  {{ selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? funcRole : editType !== 'sub-Skill' && editType !== 'create-sub-skill' ? 'Skill' : 'Sub-Skill' }} Message
                </p>
                <input
                  v-model="skillMessage"
                  class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full"
                  type="text"
                  :placeholder="`Enter ${selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? funcRole : editType !== 'sub-Skill' && editType !== 'create-sub-skill' ? 'Skill' : 'Sub-Skill'} message...`"
                />
              </div>
              <!-- eslint-disable-next-line vue/no-parsing-error -->
              <div v-if="(selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' && funcRole === 'Role') || (editType !== 'sub-Skill' && editType !== 'create-sub-skill')" class="relative p-4 w-full">
                <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">
                  {{ selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? funcRole : editType !== 'sub-Skill' && editType !== 'create-sub-skill' ? 'Skill' : 'Sub-Skill' }} Description
                </p>
                <textarea
                  v-model="skillDescription"
                  class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full"
                  type="text"
                  :placeholder="`Enter ${selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? funcRole : editType !== 'sub-Skill' && editType !== 'create-sub-skill' ? 'Skill' : 'Sub-Skill'} description...`"
                />
              </div>
              <div v-if="selectedMaster !== '074cdf81-f882-4196-8bee-78e94c22724c' && editType !== 'sub-Skill' && editType !== 'create-sub-skill'" class="flex items-center relative px-4 w-full">
                <toggle-button :toggle-val="addToMyPlan" @toggleValReturn="(status) => (addToMyPlan = status)" :confirmation-modal="false" :modal-props="{}" />
                <p class="text-xs mx-4">Add to My Plan</p>
              </div>
              <div v-if="selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' && (editType === 'sub-Skill' || editType === 'create-sub-skill')">
                <h1 class="px-4 py-2 mt-4 text-sm font-semibold text-genome-dark-blue">Mandate Data</h1>
                <div class="relative p-4 pb-0 w-full">
                  <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Header</p>
                  <textarea v-model="mandateData.header" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter header text..." />
                </div>
                <div class="mt-4">
                  <div class="w-full">
                    <h1 class="px-4 py-2 mt-4 text-sm font-semibold text-genome-dark-blue">Industry</h1>
                    <div class="relative px-4 py-2 w-full">
                      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Mandatory</p>
                      <input v-model="mandateData.master.industry_mandatory" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter description..." />
                    </div>
                    <div class="relative px-4 py-2 w-full">
                      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Non-mandatory</p>
                      <input v-model="mandateData.master.industry_non_mandatory" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter description..." />
                    </div>
                  </div>
                  <div class="w-full">
                    <h1 class="px-4 py-2 mt-4 text-sm font-semibold text-genome-dark-blue">Function Domain and Service Line</h1>
                    <div class="relative px-4 py-2 w-full">
                      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Mandatory</p>
                      <input v-model="mandateData.master.domain_mandatory" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter description..." />
                    </div>
                    <div class="relative px-4 py-2 w-full">
                      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Non-mandatory</p>
                      <input v-model="mandateData.master.domain_non_mandatory" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter description..." />
                    </div>
                  </div>
                  <div class="w-full">
                    <h1 class="px-4 py-2 mt-4 text-sm font-semibold text-genome-dark-blue">Digital</h1>
                    <div class="relative px-4 py-2 w-full">
                      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Mandatory</p>
                      <input v-model="mandateData.master.digital_mandatory" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter description..." />
                    </div>
                    <div class="relative px-4 py-2 w-full">
                      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Non-mandatory</p>
                      <input v-model="mandateData.master.digital_non_mandatory" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter description..." />
                    </div>
                  </div>
                  <div class="w-full">
                    <h1 class="px-4 py-2 mt-4 text-sm font-semibold text-genome-dark-blue">Professional</h1>
                    <div class="relative px-4 py-2 w-full">
                      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Mandatory</p>
                      <input v-model="mandateData.master.professional_mandatory" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter description..." />
                    </div>
                    <div class="relative px-4 py-2 w-full">
                      <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Non-mandatory</p>
                      <input v-model="mandateData.master.professional_non_mandatory" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter description..." />
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative p-4 w-full flex justify-end space-x-4">
                <button @click="clearData()" class="button px-4 py-1 rounded text-sm bg-white text-blue-800 border">Cancel</button>
                <button
                  @click="addUpdateSkillFunc('Skill updated successfully!')"
                  :disabled="!skillName.length"
                  :class="{ 'bg-genome-dark-blue border-genome-dark-blue': skillName.length }"
                  class="button px-4 py-1 rounded text-sm bg-genome-dark-blue text-white border border-blue-300"
                >
                  {{ masterHierarchyId && skillId ? 'Update' : 'Create' }}
                </button>
              </div>
            </div>
          </modal-comp>
        </div>
      </div>
    </section>
    <section class="mx-auto mt-4 bg-white rounded-t-lg shadow px-6">
      <div class="relative my-1 pt-6">
        <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">
          Search {{ selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? funcRole : editType !== 'sub-Skill' && editType !== 'create-sub-skill' ? 'Skill' : 'Sub-Skill' }}
        </p>
        <input
          v-model="searchSkill"
          class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded h-full"
          type="text"
          :placeholder="`Search ${selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c' ? funcRole : editType !== 'sub-Skill' && editType !== 'create-sub-skill' ? 'Skill' : 'Sub-Skill'} name...`"
        />
      </div>
      <section class="flex items-center justify-between border-b border-gray-100">
        <p class="my-4 text-base">Total results: {{ (filteredSkills && filteredSkills.length) || 0 }}</p>
        <button @click="getAllSkills()" class="py-1 px-4 text-sm text-blue-900 bg-white border border-blue-900 rounded mr-1.5">Refresh Table</button>
      </section>
    </section>
    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <SkillTable1 :skills-by-master="filteredSkills" :is-loading="isLoading" :selected-master="selectedMaster" @editSkill="editSkill" @updateSkillStatus="updateSkillStatus" @createSubSkill="createSubSkill" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SkillTable1 from '@/components/SkillTable1.vue';
// eslint-disable-next-line object-curly-newline
import { PlusIcon } from 'vue-feather-icons';
import ModalComp from '@/components/ModalComp.vue';
import ToggleButton from '@/components/global/ToggleButton.vue';

const generateUUID = () => {
  let d = new Date().getTime(); // Timestamp
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  // Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};
export default {
  name: 'SkillPolicy',
  components: {
    SkillTable1,
    PlusIcon,
    ModalComp,
    ToggleButton,
  },
  data() {
    return {
      modalTitle: 'Skill',
      skillName: '',
      skillMessage: null,
      skillDescription: '',
      archetypeError: false,
      archeTypeList: [
        {
          id: 1,
          title: 'archeType1',
        },
        {
          id: 2,
          title: 'archeType2',
        },
        {
          id: 3,
          title: 'archeType3',
        },
        {
          id: 4,
          title: 'archeType4',
        },
        {
          id: 5,
          title: 'Not Applicable',
        },
      ],
      generateUUID,
      selectedMaster: '',
      archetype: {},
      archetypeDef: {
        id: 5,
        title: 'Not Applicable',
      },
      masterHierarchyId: null,
      skillId: null,
      parentId: null,
      skillStatus: true,
      addToMyPlan: false,
      isLoading: false,
      editType: '',
      searchSkill: '',
      funcRole: 'Function',
      masterSkills: [],
      profTypeList: ['Beginner', 'Intermediate', 'Proficient', 'Expert', 'NA'],
      mandTypeList: ['nonmandatory', 'mandatory', 'optional'],
      userPayload: {},
      mandateData: {
        header: '',
        master: {
          industry_mandatory: '',
          industry_non_mandatory: '',
          domain_mandatory: '',
          domain_non_mandatory: '',
          digital_mandatory: '',
          digital_non_mandatory: '',
          professional_mandatory: '',
          professional_non_mandatory: '',
        },
      },
      mandateData1: {
        header: '',
        master: {
          industry_mandatory: '',
          industry_non_mandatory: '',
          domain_mandatory: '',
          domain_non_mandatory: '',
          digital_mandatory: '',
          digital_non_mandatory: '',
          professional_mandatory: '',
          professional_non_mandatory: '',
        },
      },
    };
  },
  watch: {
    skillsByMaster(val) {
      if (this.selectedMaster === '') this.selectedMaster = val?.[0]?.id;
      this.masterSkills = val?.filter((obj) => obj.id === this.selectedMaster)?.[0].MasterHierarchy || [];
    },
    selectedMaster(val) {
      const ab = this.skillsByMaster?.filter((obj) => obj.id === val) || [];
      if (ab[0] && 'MasterHierarchy' in ab[0]) {
        this.masterSkills = ab[0].MasterHierarchy || [];
      }
    },
  },
  computed: {
    ...mapGetters(['skillsByMaster']),
    filteredSkills() {
      return this.masterSkills.filter((obj) => obj.KeywordsGroup.Keywords.name?.toLowerCase()?.includes(this.searchSkill?.toLowerCase()));
    },
  },
  async beforeMount() {
    this.userPayload = JSON.parse(localStorage.getItem('rbac_user'));
    if (this.userPayload && this.userPayload.pages && !this.userPayload.pages.some((obj) => obj.code === 5)) this.$router.push('/');
  },
  mounted() {
    this.getAllSkills();
  },
  methods: {
    ...mapActions(['getSkills', 'addSkill', 'updateSkill']),
    async getAllSkills() {
      this.isLoading = true;
      await this.getSkills();
      this.isLoading = false;
    },
    clearData() {
      this.$refs['add-update-skill-popup'].$data.isOpen = false;
      this.skillName = '';
      this.skillDescription = '';
      this.archetype = {};
      this.skillMessage = null;
      this.skillStatus = true;
      this.addToMyPlan = false;
      this.editType = '';
      this.masterHierarchyId = null;
      this.skillId = null;
      this.parentId = null;
      this.funcRole = 'Function';
      this.mandateData = this.mandateData1;
    },
    async addUpdateSkillFunc(msg) {
      const mandateDataObj = {
        header: this.mandateData.header,
        master: [
          {
            masterType: 'industry',
            Recommendation: [
              {
                type: 'mandatory',
                description: this.mandateData.master.industry_mandatory,
              },
              {
                type: 'nonmandatory',
                description: this.mandateData.master.industry_non_mandatory,
              },
            ],
          },
          {
            masterType: 'service_line_or_domain',
            Recommendation: [
              {
                type: 'mandatory',
                description: this.mandateData.master.domain_mandatory,
              },
              {
                type: 'nonmandatory',
                description: this.mandateData.master.domain_non_mandatory,
              },
            ],
          },
          {
            masterType: 'digital_technology',
            Recommendation: [
              {
                type: 'mandatory',
                description: this.mandateData.master.digital_mandatory,
              },
              {
                type: 'nonmandatory',
                description: this.mandateData.master.digital_non_mandatory,
              },
            ],
          },
          {
            masterType: 'proff_skill',
            Recommendation: [
              {
                type: 'mandatory',
                description: this.mandateData.master.professional_mandatory,
              },
              {
                type: 'nonmandatory',
                description: this.mandateData.master.professional_non_mandatory,
              },
            ],
          },
        ],
      };
      mandateDataObj.master.map((obj) => {
        // eslint-disable-next-line no-param-reassign
        obj.Recommendation = obj.Recommendation.filter((objj) => objj.description !== '');
        return obj;
      });
      const variables = {
        groupId: '95e686e7-5651-4c92-8832-6dda6aec35d5',
        masterId: this.selectedMaster,
        name: this.skillName,
        description: this.skillDescription,
        archetype: this.archetype.title,
        message: this.skillMessage || null,
        mandateData: this.funcRole === 'Role' ? mandateDataObj : {},
        status: this.skillStatus,
        addToMyPlan: this.addToMyPlan,
        ohrId: parseFloat(this.userPayload.ohrId),
      };
      if (this.masterHierarchyId && this.skillId) {
        variables.masterHierarchyId = this.masterHierarchyId;
        variables.skillId = this.skillId;
        try {
          const ab = await this.updateSkill(variables);
          if (ab && ab?.data?.KeywordsUpdate) {
            this.$store.dispatch('addToastNotifications', {
              text: msg,
              type: 'success',
              timer: 3000,
            });
            this.clearData();
          }
        } catch (error) {
          this.$store.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 3000,
          });
          this.clearData();
        }
      } else {
        variables.parentId = this.parentId;
        try {
          const ab = await this.addSkill(variables);
          if (ab && ab?.data?.KeywordsUpdate) {
            this.$store.dispatch('addToastNotifications', {
              text: 'Skill added successfully!',
              type: 'success',
              timer: 3000,
            });
            this.clearData();
          }
        } catch (error) {
          this.$store.dispatch('addToastNotifications', {
            text: 'Something went wrong!',
            type: 'error',
            timer: 3000,
          });
          this.clearData();
        }
      }
    },
    addNewSkill() {
      this.skillName = '';
      this.skillDescription = '';
      this.archetype = {};
      this.skillMessage = null;
      this.masterHierarchyId = null;
      this.skillId = null;
      this.skillStatus = true;
      this.addToMyPlan = false;
      this.editType = 'Skill';
      this.funcRole = 'Function';
      this.modalTitle = 'Add New Skill';
      this.$refs['add-update-skill-popup'].$data.isOpen = true;
    },
    createSubSkill(val) {
      this.skillName = '';
      this.skillDescription = '';
      this.archetype = {};
      this.skillMessage = null;
      this.masterHierarchyId = null;
      this.skillId = null;
      this.skillStatus = true;
      this.modalTitle = 'Add Sub-Skill';
      if (val.editType === 'create-sub-skill' && this.selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c') {
        this.funcRole = 'Role';
        this.mandateData = this.mandateData1;
      }
      this.editType = val.editType;
      this.parentId = val.id;
      this.$refs['add-update-skill-popup'].$data.isOpen = true;
    },
    editSkill(val) {
      this.skillName = '';
      this.mandateData = this.mandateData1;
      this.skillDescription = '';
      this.archetype = {};
      this.skillMessage = null;
      this.skillId = null;
      this.masterHierarchyId = null;
      this.modalTitle = val.editType === 'sub-Skill' ? 'Edit Sub-Skill' : 'Edit Skill';
      if (val.editType === 'Skill' && this.selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c') this.funcRole = 'Function';
      if (val.editType === 'sub-Skill' && this.selectedMaster === '074cdf81-f882-4196-8bee-78e94c22724c') {
        this.funcRole = 'Role';
        this.mandateData.header = val.mandateData.header;
        val.mandateData.master.forEach((obj) => {
          obj.Recommendation.forEach((objj) => {
            if (obj.masterType === 'industry' && objj.type === 'mandatory') this.mandateData.master.industry_mandatory = objj.description;
            else if (obj.masterType === 'industry' && objj.type === 'nonmandatory') this.mandateData.master.industry_non_mandatory = objj.description;
            else if (obj.masterType === 'service_line_or_domain' && objj.type === 'mandatory') this.mandateData.master.domain_mandatory = objj.description;
            else if (obj.masterType === 'service_line_or_domain' && objj.type === 'nonmandatory') this.mandateData.master.domain_non_mandatory = objj.description;
            else if (obj.masterType === 'digital_technology' && objj.type === 'mandatory') this.mandateData.master.digital_mandatory = objj.description;
            else if (obj.masterType === 'digital_technology' && objj.type === 'nonmandatory') this.mandateData.master.digital_non_mandatory = objj.description;
            else if (obj.masterType === 'proff_skill' && objj.type === 'mandatory') this.mandateData.master.professional_mandatory = objj.description;
            else if (obj.masterType === 'proff_skill' && objj.type === 'nonmandatory') this.mandateData.master.professional_non_mandatory = objj.description;
          });
        });
      }
      this.$refs['add-update-skill-popup'].$data.isOpen = true;
      this.skillName = val.KeywordsGroup.Keywords.name || '';
      this.skillDescription = val.KeywordsGroup.Keywords.description || '';
      this.editType = val.editType;
      if (this.editType === 'Skill' && this.selectedMaster !== '074cdf81-f882-4196-8bee-78e94c22724c') this.archetypeDef = this.archeTypeList.find((obj) => obj.title.toLowerCase() === val.archetype.toLowerCase()) || {};
      this.skillMessage = val.message || null;
      this.masterHierarchyId = val.id || null;
      this.skillId = val.KeywordsGroup.Keywords.id || null;
      this.skillStatus = val.status;
      this.addToMyPlan = val.addToMyPlan;
    },
    async updateSkillStatus(val) {
      console.log(val);
      this.skillName = val.KeywordsGroup.Keywords.name || '';
      this.skillDescription = val.KeywordsGroup.Keywords.description || '';
      this.editType = val.editType;
      if (val.editType === 'sub-Skill') this.archetype.title = val.archetype;
      else this.archetype = {};
      this.skillMessage = val.message || null;
      this.masterHierarchyId = val.id || null;
      this.skillId = val.KeywordsGroup.Keywords.id || null;
      this.skillStatus = val.status;
      this.addToMyPlan = val.addToMyPlan;
      await this.addUpdateSkillFunc('Status updated successfully!');
    },
  },
};
</script>

<style lang="postcss">
.select-radio-input {
  /* @apply space-x-2; */
  input {
    @apply fixed left-0 opacity-0 invisible;
    margin-left: -9999px;

    &:checked {
      /* stylelint-disable-next-line */
      + label {
        @apply text-white bg-genome-dark-blue;
      }
    }
  }
}
</style>
