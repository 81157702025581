<template>
  <main class="flex w-full min-h-screen bg-gray-100">
    <div class="flex flex-col w-full">
      <Header :nav-expand="navExpand" @toggle-nav="navExpand = !navExpand" />
      <div v-if="userAccessAllowed" class="flex max-h-screen min-h-screen pt-16">
        <Sidebar class="w-72" v-if="navExpand" />
        <router-view class="w-full p-12 overflow-y-auto" />
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Dashboard',
  data() {
    return {
      navExpand: false,
      userAccessAllowed: false,
    };
  },
  async beforeMount() {
    try {
      await this.fetchUserRole();
      this.userAccessAllowed = true;
      const redirectPath = JSON.parse(localStorage.getItem('rbac_user'));
      if (redirectPath?.defaultRoute && !window.location.pathname.includes(redirectPath?.defaultRoute)) this.$router.push(redirectPath?.defaultRoute);
      if (redirectPath && redirectPath?.pages && !redirectPath?.pages?.some((obj) => obj.code < 100)) {
        this.userAccessAllowed = false;
        this.$store.dispatch('addToastNotifications', {
          text: "Oops... You don't have permission to any of the page on dashboard. Please contact Admin to get permission.",
          type: 'error',
          timer: 8000,
        });
      }
    } catch (e) {
      this.userAccessAllowed = false;
    }
  },
  methods: {
    ...mapActions(['fetchUserRole']),
  },
};
</script>
