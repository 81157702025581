import skillsActionModule from './policy-actions';

const initializeState = () => ({
  policies: [],
});

export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setPoliciesInStore(state, payload) {
      state.policies = payload || [];
    },
  },
  actions: skillsActionModule({ $http }),
  getters: {
    policies: (state) => state.policies,
  },
});
