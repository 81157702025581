<template>
  <main :data-from-date="payload.fromDate">
    <div v-if="initialDataLoading" class="h-72 flex flex-col items-center justify-center text-center">
      <ring-loader class="mb-5" height="35px" width="35px" color="rgb(0, 173, 207)" />
      <h5 class="text-lg">Preparing dashboard...</h5>
      <p class="mb-5">Please be patient</p>
    </div>
    <div v-else-if="dataLoadError.length" class="flex flex-col items-center justify-center h-full text-center">
      <i class="icon os-icon os-icon-robot-1" />
      <h5 class="text-lg">{{ dataLoadError }}</h5>
      <button class="px-4 py-2 mt-5 border border-gray-700 rounded" @click.prevent="reloadWindow">Reload window</button>
    </div>
    <section v-else>
      <section class="flex flex-wrap items-center justify-between w-full p-6 bg-white rounded-lg shadow">
        <div class="flex items-center justify-between my-2">
          <h3 class="text-lg text-gray-800">Download LS Reports</h3>
        </div>
        <div class="flex flex-wrap gap-3 my-2">
          <!-- <div class="relative my-1">
            <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">Week</p>
            <input v-model="selectWeek" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded" type="week" @change="resetInputs" />
          </div>
          <div class="relative my-1">
            <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">Month</p>
            <input v-model="selectMonth" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded" type="month" @change="resetInputs" />
          </div>
          <div class="relative my-1">
            <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">Year</p>
            <select id="year" v-model="selectYear" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded">
              <option v-for="index in 50" :key="index" :value="2000 + index" class="capitalize">
                {{ 2000 + index }}
              </option>
            </select>
          </div> -->
          <div class="relative my-1 mx-4 h-10">
            <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">Report Type</p>
            <select id="year" v-model="selectReportType" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded capitalize">
              <option disabled selected value="">-- select --</option>
              <option v-for="reportType in reportTypes" :key="reportType" :value="reportType" class="capitalize">
                {{ reportType }}
              </option>
            </select>
          </div>
          <button
            :class="{
              loading: request.keys.includes('executeLSQuery') && request.inProgress,
            }"
            :disabled="request.keys.includes('executeLSQuery') && request.inProgress"
            class="button my-auto px-6 h-10 text-sm text-white bg-blue-500 rounded"
            @click="executeLSQuery(selectReportType)"
          >
            Generate report
            <ring-loader />
          </button>
        </div>
      </section>
      <LSReportTable @refreshTableData="refreshTableData" />
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import calenderMixin from '@/mixins/calender-mixin';

export default {
  name: 'LearnerScoreReports',
  components: {
    LSReportTable: () => import('@/components/LSReportTable.vue'),
  },
  mixins: [calenderMixin],
  data() {
    return {
      currentTime: new Date(),
      selectedReport: '',
      initialDataLoading: false,
      dataLoadError: '',
      ohrId: '',
      correctOHR: 0,
      selectReportType: '',
      reportTypes: ['weekly', 'monthly', 'yearly', 'manager', 'waves'],
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(['request', 'executions']),
    payload() {
      const payload = {
        ohrId: this.correctOHR,
        toDate: this.endDate,
        fromDate: this.startDate,
        report: this.selectedReport,
      };
      if ((payload.toDate && payload.fromDate) || (!payload.toDate && !payload.fromDate)) {
        this.fetchQueryExecHistory(payload);
      }
      return payload;
    },
  },
  async beforeMount() {
    this.initialDataLoading = true;
    try {
      await this.fetchLSReports();
      // await this.fetchUserRole();
      // await this.fetchAllReportsLists();
    } catch (e) {
      console.warn(e);
      this.dataLoadError = 'Critical error, please reload the window or contact support.';
      this.$store.dispatch('addToastNotifications', {
        text: this.dataLoadError,
        type: 'error',
        timer: 4000,
      });
    }
    this.initialDataLoading = false;
  },
  methods: {
    ...mapActions(['fetchLSReports', 'fetchQueryExecHistory', 'fetchUserRole', 'fetchAllReportsLists', 'executeLSQuery']),
    clearFilters() {
      this.ohrId = '';
      this.correctOHR = 0;
      this.endDate = '';
      this.startDate = '';
      this.selectedReport = '';
      this.resetInputs();
    },
    async refreshTableData() {
      this.initialDataLoading = true;
      await this.fetchLSReports();
      this.initialDataLoading = false;
    },
    resetInputs() {
      document.querySelectorAll('input[name="dateRangeRadioSelector"]').forEach((x) => {
        const elem = x;
        elem.checked = false;
      });
    },
    invalidOhrId() {
      const isValidOHR = !this.ohrId.toString().match(/^[8 | 7 | 3 | 4 | 6 | 5 | 2 | 1][0-9]{8}$/g);
      if (isValidOHR) {
        this.$store.dispatch('addToastNotifications', {
          text: 'Invalid OHRID!',
          timer: 2000,
          type: 'error',
        });
        this.correctOHR = 0;
      } else this.correctOHR = parseInt(this.ohrId, 10);
      return isValidOHR;
    },
    reloadWindow() {
      window.location.reload();
    },
  },
};
</script>

<style lang="postcss">
.date-select-actions {
  /* @apply space-x-2; */
  input {
    @apply fixed left-0 opacity-0 invisible;
    margin-left: -9999px;

    &:checked {
      /* stylelint-disable-next-line */
      + label {
        @apply bg-blue-50 text-blue-700;
      }
    }
  }
  label {
    @apply inline-block rounded-lg font-medium leading-6 px-3 focus:outline-none hover:text-blue-600 focus:text-blue-600 cursor-pointer text-sm border border-gray-200 text-gray-700;
    &:not(:first-of-type) {
      @apply ml-2;
    }
  }
}
</style>
