<template>
  <div>
    <header>
      <ul class="flex my-2 mx-6 font-medium cursor-pointer">
        <li class="mr-6 text-gray-500 hover:text-blue-800 capitalize" v-for="tab in tabs" :key="tab" @click="switchTab(tab)" :class="{ 'border-b-2 border-blue-800 text-blue-800': activeTab === tab }">
          <slot :name="tabHeadSlotName(tab)">
            {{ tab }}
          </slot>
        </li>
      </ul>
    </header>
    <main>
      <div class="tab-panel px-6 mt-5">
        <section class="md:px-12 px-2 mt-4 pb-2">
          <SearchInput
            id="global-search-input"
            value="Personal Files and Shared Files..."
            :changed-tab-name="changedTabName"
            :autofocus="true"
            :autofocus-time="500"
            :placeholder="activeTab !== 'all' ? `Search ${activeTab}...` : 'Search folders, files or users...'"
            class="mt-4 mb-5 md:text-2xl sm:text-xl text-md text-gray-800"
            input-class="
              appearance-none
              block
              w-full
              border-b
              py-2
              focus:outline-none
              text-gray-800
              border-gray-600
              focus:bg-white
              focus:border-gray-800
              "
          />
        </section>
        <slot :name="tabPanelSlotName" />
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TabView',
  components: {
    // SearchInput: () => import('@/components/SearchInput.vue'),
  },
  props: {
    initialTab: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      changedTabName: 'Policies',
      activeTab: this.initialTab,
    };
  },
  computed: {
    tabPanelSlotName() {
      return `tab-panel-${this.activeTab}`;
    },
  },
  mounted() {},
  methods: {
    ...mapActions(['setDropDownCoordinates', 'clearSearchResults', 'addToastNotifications']),
    tabHeadSlotName(tabName) {
      return `tab-head-${tabName}`;
    },
    switchTab(tabName) {
      this.activeTab = tabName;
      this.$emit('switchedSearchTabEmit', tabName);
      this.changedTabName = tabName;
      // this.clearSearchResults();
    },
  },
};
</script>
