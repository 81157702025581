<template>
  <div id="app">
    <StoreRouterComp v-if="nextRouteLink" :next-route="nextRouteLink" />
    <StoreToastNotificationComp />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <!-- <p class="text-xs text-gray-300 mx-auto py-2 w-28">Version: v{{ version }}</p> -->
  </div>
</template>

<script>
import { Hub } from 'aws-amplify';
import { mapGetters } from 'vuex';
import '@/styles/tailwind.css';
import '@/styles/app.css';
import { version } from '../package.json';

console.warn('App version: ', version); // eslint-disable-line

export default {
  name: 'App',
  components: {
    StoreRouterComp: () => import('@/components/StoreRouter.vue'),
    StoreToastNotificationComp: () => import('@/components/StoreToastNotification.vue'),
  },
  data() {
    return {
      version,
    };
  },
  computed: {
    ...mapGetters(['nextRouteLink']),
  },
  mounted() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('Hub event: ', event, data);
      const email = (data.storage.user && JSON.parse(data.storage.user).email) || (data.attributes && data.attributes.email) || (data.signInUserSession.idToken.payload && data.signInUserSession.idToken.payload.email);
      const username = data.username.split('_').pop();
      const ohrId = username.split('@').shift() || '';
      switch (event) {
        case 'signIn':
          this.$store.dispatch('setSession', {
            accessToken: data.signInUserSession.accessToken.jwtToken,
            user: { email, username, ohrId },
          });
          break;
        case 'signOut':
          this.$store.dispatch('logout');
          break;
        case 'customOAuthState':
          this.setState({ customState: data });
          break;
        default:
      }
    });
  },
};
</script>
<style src="@fortawesome/fontawesome-free/css/all.css" />
