<template>
  <section class="flex flex-col items-center justify-center h-screen text-lg text-gray-700">
    <!-- <button @click="login">Open Okta</button> -->
    <img class="w-48 mb-3" src="@/assets/genome-header.svg" />
    <p class="mb-20">Taking you to Okta login page...</p>
  </section>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'LoginPage',
  mounted() {
    setTimeout(() => {
      Auth.federatedSignIn({ provider: 'ApiSvcIdentityPool' });
    }, 2000);
  },
  methods: {
    login() {
      Auth.federatedSignIn({ provider: 'ApiSvcIdentityPool' });
    },
  },
};
</script>
