<template>
  <main id="tasks">
    <div class="pt-5 mt-5 bg-white rounded-lg shadow-md">
      <section class="flex items-center justify-between px-4 pb-4 border-b border-gray-100">
        <h3 class="text-lg text-gray-800">Showing all reports</h3>
        <button
          :class="{
            loading: request.keys.includes('fetchLSReports') && request.inProgress,
          }"
          :disabled="request.keys.includes('fetchLSReports') && request.inProgress"
          class="button focus:outline-none hover:text-gray-700 hover:border-gray-500 flex items-center px-3 ml-4 text-xs font-medium leading-6 text-gray-600 border border-gray-400 rounded-lg cursor-pointer"
          @click="$emit('refreshTableData')"
        >
          <RefreshCwIcon size="1x" class="mr-2" />Refresh reports
          <ring-loader color="rgba(59, 130, 246, 1)" width="20px" height="20px" />
        </button>
      </section>
      <section class="w-full mx-auto overflow-x-auto">
        <table v-if="LSreportsList.length" class="whitespace-nowrap w-full border-t-0 border-collapse">
          <thead class="text-gray-dark">
            <tr class="">
              <th v-for="column in columnDefs" :key="column.heading">
                <p class="flex items-center h-10 px-4 pr-10 text-xs font-normal text-left">
                  {{ column.heading }}
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(result, index) in LSreportsList" :key="index" class="hover:bg-indigo-50 h-12 text-sm text-gray-800" :class="{ 'bg-main': index % 2 === 0 }">
              <td v-for="column in columnDefs" :key="column.heading" :class="[column.field === 'status' && result[column.field] === 'completed' ? 'w-10' : '']">
                <p class="relative flex items-center px-4 capitalize">
                  <span v-if="column.field === 'status' && 'location' in result" class="relative flex items-center justify-start w-full">
                    <ring-loader
                      v-if="request.inProgress && (request.keys.includes('downloadLSFromS3') || request.keys.includes('regenerateReport')) && fileProcessingId === result.queryexecutionid"
                      color="rgba(59, 130, 246, 1)"
                      width="20px"
                      height="20px"
                    />
                    <span v-else class="flex items-center justify-start w-full space-x-2">
                      <button
                        class="hover:text-blue-500 hover:border-blue-500 focus:outline-none px-2 py-1 text-sm text-gray-500 transition-colors bg-white border border-gray-300 rounded-md outline-none"
                        title="Download report"
                        @click="downloadReport(result)"
                      >
                        download
                      </button>
                    </span>
                  </span>
                  <span v-else-if="column.field === 'status' && !'location' in result" class="relative flex items-center justify-center w-full">{{ result[column.field] }}</span>
                  <span v-else-if="column.field === 'userCount'">{{ result[column.field] }}</span>
                  <span v-else-if="column.field === 'type'">{{ result[column.field] }}</span>
                  <span v-else-if="column.field === 'sk'">{{ result[column.field] | dateFormat }}</span>
                  <span v-else>{{ result[column.field] ? result[column.field] : '--' }}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else-if="request.inProgress && request.keys.includes('fetchLSReports')" class="h-72 flex flex-col items-center justify-center text-center">
          <ring-loader class="mb-5" height="35px" width="35px" color="rgb(0, 173, 207)" />
          <h5 class="text-lg">Fetching reports...</h5>
          <p class="mb-5">Please be patient</p>
        </div>

        <div v-else class="h-72 flex flex-col items-center justify-center text-center">
          <i class="icon os-icon os-icon-robot-1" />
          <h5 class="text-lg">No reports for selected filters</h5>
          <p class="mb-5">
            Try changing the filter criteria
            <span v-if="filters.report">or generate a new report</span>
          </p>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { RefreshCwIcon /* DownloadCloudIcon */ } from 'vue-feather-icons';

const STATUS_MAPPINGS = {
  1: 'EXECUTION_STARTED',
  2: 'QUEUED',
  3: 'PROCESSING',
  4: 'COMPLETED',
  5: 'ERROR',
  // 1: 'PROCESSING',
  // 2: 'COMPLETED',
  // 3: 'ERROR',
};

export default {
  name: 'LSReportTable',
  components: {
    RefreshCwIcon,
    // DownloadCloudIcon,
  },
  props: {
    reportType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileProcessingId: null,
      fileReGeneratingId: null,
      columnDefs: [
        {
          field: 'userCount',
          heading: 'Users Count',
        },
        {
          field: 'sk',
          heading: 'Date',
        },
        {
          field: 'type',
          heading: 'Type',
        },
        {
          field: 'status',
          heading: 'Status',
        },
      ],
      statusMapping: STATUS_MAPPINGS,
    };
  },
  computed: {
    ...mapGetters(['LSreportsList', 'request']),
    inProgressExecutions() {
      return this.LSreportsList.filter((x) => x.status <= 3);
    },
  },
  methods: {
    ...mapActions(['downloadLSFromS3', 'regenerateReport']),
    async downloadReport({ location, pk }) {
      console.log('hii');
      this.fileProcessingId = pk;
      try {
        const file = pk + location.split(pk).pop();
        const bucketName = location.match(/(?<=\/)(.*?)(?=\/)/gi).pop();
        console.log('file-', file, 'bucket-', bucketName);
        const signedUrl = await this.downloadLSFromS3({ bucketName, file });
        const link = document.createElement('a');
        link.href = signedUrl;
        console.log('link-', link);
        link.click();
      } catch (err) {
        console.warn(err);
        this.$store.dispatch('addToastNotifications', {
          text: `File download failed with error: ${err.message}`,
          type: 'error',
          timer: 4000,
        });
      }
      this.fileProcessingId = null;
    },
  },
};
</script>
<style scoped>
table,
td {
  border: 0.5px solid rgb(202 202 202 / 30%);
}
</style>
