<template>
  <section class="ring-loader-container" :style="`height: ${height}; width: ${width}`">
    <div :style="`border-color: ${color} transparent transparent transparent;border-width: ${thickness}; height: ${height}; width: ${width}`" />
    <div :style="`border-color: ${color} transparent transparent transparent;border-width: ${thickness}; height: ${height}; width: ${width}`" />
    <div :style="`border-color: ${color} transparent transparent transparent;border-width: ${thickness}; height: ${height}; width: ${width}`" />
    <div :style="`border-color: ${color} transparent transparent transparent;border-width: ${thickness}; height: ${height}; width: ${width}`" />
  </section>
</template>

<script>
export default {
  name: 'RingLoader',
  props: {
    color: {
      type: String,
      default: '#fff',
    },
    thickness: {
      type: String,
      default: '3px',
    },
    width: {
      type: String,
      default: '25px',
    },
    height: {
      type: String,
      default: '25px',
    },
  },
};
</script>

<style lang="postcss" scoped>
.ring-loader-container {
  display: inline-block;
  width: 25px;
  height: 25px;
  transform: translate(0%, 0%);

  div {
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: 25px;
    height: 25px;
    border: 3px solid rgb(255, 255, 255);
    border-radius: 50%;
    animation: ring-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes ring-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button {
  .ring-loader-container {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: opacity, transform, visibility 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
