<template>
  <section>
    <div class="overflow-x-auto bg-white rounded-b-lg shadow overflow-y-auto relative w-auto text-gray-600 text-sm">
      <div class="w-full flex tracking-wider uppercase font-bold justify-between items-center bg-gray-50 sticky top-0 border-b border-gray-200 z-10">
        <div class="px-6 py-2 w-1/2">Name</div>
        <div class="flex items-center w-fit">
          <div class="px-0 py-2 w-fit">Status</div>
          <div class="px-10 py-2 w-fit">Updated On</div>
          <div class="px-14 py-2 w-fit">Action</div>
        </div>
      </div>
      <div v-if="isLoading" class="flex overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative w-auto text-gray-600 text-sm">
        <ring-loader class="my-5 mx-auto" height="35px" width="35px" color="rgb(0, 173, 207)" />
      </div>
      <div v-else-if="!skillsByMaster.length && !isLoading" class="flex overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative w-auto text-gray-600 text-sm">
        <span class="flex mx-auto my-8">Don't have skills show here...</span>
      </div>
      <div v-else v-for="(skill, index) in skillsByMaster" :key="index" class="w-full">
        <div v-if="skill.parentId === null" class="w-full flex justify-between items-center border-b border-gray-200">
          <div class="flex items-center px-6 py-2 w-1/2">
            <span
              v-if="skillsByMaster.filter((obj) => obj.parentId === skill.id).length"
              @click="rotateArrow(`${skill.id}`, `sub-${skill.id}`)"
              class="focus:outline-none outline-none p-2 md:p-0 text-white text-sm z-10 sub-container-visibility-toggle-checkbox"
            >
              <i :id="`${skill.id}`" class="p-1 fas fa-chevron-down bg-red-400 border border-red-500 rounded"></i>
            </span>
            <span v-else class="px-2.5"></span>
            <span class="px-4">{{ skill.KeywordsGroup.Keywords.name }}</span>
          </div>
          <div class="flex items-center w-fit">
            <div class="px-6 py-2 w-fit">
              <!-- <div class="flex text-sm my-auto w-1/5 text-center items-center capitalize">
                <div class="h-2 w-2 rounded-full mr-2 bg-green-600"></div>
                <div>{{ skill.KeywordsGroup.Keywords.status ? 'Active' : 'Disabled' }}</div>
              </div> -->
              <toggle-button
                :modal-id="index"
                :toggle-val="skill.status"
                @toggleValReturn="(status) => ((skill.status = status), $emit('updateSkillStatus', { ...skill, status, editType: 'Skill' }))"
                :confirmation-modal="true"
                :modal-props="modalProps"
              />
            </div>
            <div class="px-6 py-2 w-fit">
              {{ skill.KeywordsGroup.Keywords.updatedAt | dateFormat }}
            </div>
            <div class="px-6 py-2 w-fit flex items-center">
              <button @click="$emit('editSkill', { ...skill, editType: 'Skill' })" class="button px-2 py-0.5 rounded text-sm">
                <EditIcon size="1.2x" />
              </button>
              <router-link v-if="selectedMaster !== '074cdf81-f882-4196-8bee-78e94c22724c'" :to="`/skill-policies/${skill.id}`" class="button px-2 py-0.5 rounded text-sm">
                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip">
                  <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                </svg>
              </router-link>
              <button :class="{ invisible: selectedMaster === 'fdb55ee9-19d6-4faa-a1c2-5119cd2cd01d' }" @click="$emit('createSubSkill', { ...skill, editType: 'create-sub-skill' })" class="button px-2 py-0.5 rounded text-sm">
                <PlusIcon size="1.2x" />
              </button>
            </div>
          </div>
        </div>
        <div :id="`sub-${skill.id}`" class="w-full hidden">
          <div v-for="(subSkill, indexx) in skillsByMaster.filter((obj) => obj.parentId === skill.id)" :key="indexx" class="w-full flex justify-between items-center border-b border-gray-200 bg-gray-100">
            <div class="px-6 py-2 w-1/2 pl-20">
              {{ subSkill.KeywordsGroup.Keywords.name }}
            </div>
            <div class="flex items-center w-fit">
              <div class="px-6 py-2 w-fit">
                <!-- <div class="flex text-sm my-auto w-1/5 text-center items-center capitalize">
                  <div class="h-2 w-2 rounded-full mr-2 bg-green-600"></div>
                  <div>{{ skill.KeywordsGroup.Keywords.status ? 'Active' : 'Disabled' }}</div>
                </div> -->
                <toggle-button
                  :modal-id="index"
                  :toggle-val="subSkill.status"
                  @toggleValReturn="(status) => ((subSkill.status = status), $emit('updateSkillStatus', { ...subSkill, status, editType: 'sub-Skill' }))"
                  :confirmation-modal="true"
                  :modal-props="modalProps"
                />
              </div>
              <div class="px-6 py-2 w-fit">
                {{ subSkill.KeywordsGroup.Keywords.updatedAt | dateFormat }}
              </div>
              <div class="px-6 py-2 w-fit flex items-center">
                <button @click="$emit('editSkill', { ...subSkill, editType: 'sub-Skill' })" class="button px-2 py-0.5 rounded text-sm">
                  <EditIcon size="1.2x" />
                </button>
                <!-- <router-link :to="`/skill-policies/${subSkill.id}`" class="button px-2 py-0.5 rounded text-sm"> -->
                <div v-if="selectedMaster !== '074cdf81-f882-4196-8bee-78e94c22724c'" class="button px-2 py-0.5 rounded text-sm">
                  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-paperclip invisible"
                  >
                    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                  </svg>
                </div>
                <div v-if="selectedMaster !== 'fdb55ee9-19d6-4faa-a1c2-5119cd2cd01d'" class="div px-2 py-0.5 rounded text-sm">
                  <PlusIcon class="invisible" size="1.2x" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import { EditIcon, PlusIcon } from 'vue-feather-icons';
import ToggleButton from './global/ToggleButton.vue';
// import TableComponent from '@/components/TableComponent.vue';

export default {
  name: 'Products',
  components: { EditIcon, PlusIcon, ToggleButton },
  props: {
    skillsByMaster: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedMaster: {
      type: String,
      default: '',
    },
  },
  computed: {},
  data() {
    return {
      tableDataChange: [],
      modalProps: {
        title: 'Are you sure?',
        trueBody: 'Do you want to make this skill <b>inactive</b>?',
        falseBody: 'Do you want to make this skill <b>active</b>?',
        cancelBtnLabel: 'Cancel',
        confirmBtnLabel: 'Yes',
        selectedRows: [],
      },
    };
  },
  filters: {
    dateFormat(val) {
      return moment(val).format('DD MMM YYYY hh:mm a');
    },
  },
  methods: {
    rotateArrow(id, subId) {
      document.getElementById(id).classList.toggle('transform');
      document.getElementById(id).classList.toggle('rotate-180');
      document.getElementById(subId).classList.toggle('hidden');
    },
  },
};
</script>
