<template>
  <section class="RuleEngine">
    <h1 class="text-xl text-genome-dark-blue font-bold mb-4">Policies</h1>
    <div v-if="isOpenWindow">
      <section class="p-4 mx-auto mb-8 bg-white rounded-lg shadow">
        <div class="flex-col items-center px-1 py-4">
          <div class="flex justify-between items-center">
            <h3 class="text-lg text-genome-dark-blue font-semibold">Step 1: Add policy name</h3>
            <button @click="clearForm" class="mx-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"></path>
              </svg>
            </button>
          </div>
          <input type="text" v-model="PolicyName" placeholder="Add rule name" class="border-b border-genome-dark-blue focus:outline-none leading-tight py-1 my-2 text-gray-700 text-sm w-full md:w-56" />
          <p class="text-red-500 text-xs">{{ policyNameErr }}</p>
        </div>
        <div class="flex-col items-center px-1 my-8">
          <h3 class="text-lg text-genome-dark-blue font-semibold">Step 2: Create rules</h3>
          <h5 class="text-sm text-genome-dark-blue">Click on below button to add multiple rules</h5>
          <div class="w-auto flex-col">
            <div v-for="(rule, index) in ruleArray" :key="rule.id" class="w-auto sm:flex" :class="{ 'mt-6': index === 0 }">
              <div v-if="index === 0" class="relative w-24 sm:flex mx-4">
                <div class="absolute bottom-0 right-0 h-1/2 w-1/2 border-l border-t border-gray-300" />
              </div>
              <div v-else class="select-radio-input md:flex items-center w-24 mx-4">
                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                <input :id="'and' + rule.id" type="radio" checked :name="'andor' + rule.id" value="and" @change="updateOperator" />
                <label :for="'and' + rule.id" class="h-9 w-1/2 my-4 py-1 text-center border-t border-l border-b rounded-tl-lg rounded-bl-lg">
                  <span class="m-auto">AND</span>
                </label>
                <div class="bg-gray-300 mx-auto w-px h-full" />
                <input :id="'or' + rule.id" type="radio" :name="'andor' + rule.id" value="or" @change="updateOperator" />
                <label :for="'or' + rule.id" class="h-9 w-1/2 my-4 py-1 text-center border-t border-r border-b rounded-tr-lg rounded-br-lg">
                  <span class="nav-link">OR</span>
                </label>
              </div>
              <div class="m-4">
                <div class="relative">
                  <select :id="'fact' + rule.id" class="focus:outline-none block w-full h-full pl-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none">
                    <option disabled value="">-- select --</option>
                    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                    <option v-for="(opt, indexx) in skillsByMaster" :key="indexx" :value="opt.name" :selected="opt.name === 'Function'" :disabled="opt.name !== 'Function'" class="capitalize">
                      {{ opt.name }}
                    </option>
                  </select>
                  <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <span :id="'errorfact' + rule.id" class="text-red-500 text-xs hidden">*Please select</span>
              </div>
              <div class="m-4">
                <div class="relative">
                  <select :id="'operator' + rule.id" value="" class="focus:outline-none block w-full h-full pl-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none">
                    <option disabled selected value="">-- select --</option>
                    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                    <option v-for="(opt, indexx) in factOperatorList" :key="indexx" :value="opt.value" :selected="opt.value === 'equal'" :disabled="opt.value !== 'equal'" class="capitalize">
                      {{ opt.title }}
                    </option>
                  </select>
                  <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <span :id="'erroroperator' + rule.id" class="text-red-500 text-xs hidden">*Please select</span>
              </div>
              <div class="m-4 w-40">
                <div class="relative">
                  <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3 capitalize">Function</p>
                  <select
                    :id="'value' + rule.id"
                    v-model="defFuncVal"
                    @change="selectedValue(rule.id)"
                    class="focus:outline-none block w-full h-full pl-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none"
                  >
                    <option disabled selected value="">-- select --</option>
                    <option v-for="(opt, indexx) in selectedFunc" :key="indexx" :value="opt.id" class="capitalize">
                      {{ opt.KeywordsGroup.Keywords.name }}
                    </option>
                  </select>
                  <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <span :id="'errorvalue' + rule.id" class="text-red-500 text-xs hidden">*Please select</span>
              </div>
              <div v-if="selectedRole && selectedRole.length">
                <div class="m-4 w-40">
                  <div class="relative">
                    <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3 capitalize">Role</p>
                    <select :id="'subSkillValue' + rule.id" v-model="defRoleVal" class="focus:outline-none block w-full h-full pl-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none">
                      <option disabled selected value="">-- select --</option>
                      <option v-for="(opt, indexx) in selectedRole" :key="indexx" :value="opt.id" class="capitalize">
                        {{ opt.KeywordsGroup.Keywords.name }}
                      </option>
                    </select>
                    <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                      <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  <span :id="'errorsubSkillValue' + rule.id" class="text-red-500 text-xs hidden">*Please select</span>
                </div>
              </div>
              <!-- <div class="h-6 w-6 mx-4 my-auto">
                <ring-loader class="mb-5" height="24px" width="24px" color="rgb(0, 173, 207)" />
              </div> -->
              <button @click="removeRule(index)" class="h-8 w-8 mx-4 my-auto border rounded-full">
                <Trash2Icon size="1x" class="m-auto text-gray-500" />
              </button>
            </div>
          </div>
          <div class="w-auto sm:flex">
            <div class="relative w-24 h-32 sm:flex mx-4">
              <div v-if="ruleArray.length" class="absolute left-0 top-0 h-1/2 w-1/2 border-r border-gray-300 mx-px z-0" />
              <button @click="addRule()" :disabled="ruleArray.length === 1" class="h-8 w-8 m-auto rounded-full bg-genome-dark-blue z-10">
                <PlusIcon size="1x" class="m-auto text-white" />
              </button>
            </div>
          </div>
        </div>
        <div class="flex-col items-center px-1 py-4">
          <h3 class="text-lg text-genome-dark-blue font-semibold">Step 3: Add Proficiency</h3>
          <div class="flex relative items-center select-radio-input my-4 w-max">
            <div class="flex items-center">
              <toggle-button class="my-4" :toggle-val="isMandatory" @toggleValReturn="(val) => (isMandatory = val)" :confirmation-modal="false" :modal-props="{}" />
              <h5 class="px-4 text-sm text-genome-dark-blue">Mark skill mandatory</h5>
            </div>
          </div>
          <div v-show="!isMandatory">
            <div class="flex relative items-center select-radio-input my-4 w-max">
              <div class="absolute bg-genome-dark-blue h-px mx-2 w-11/12 z-0" />
              <div v-for="prof in proficienciesList" :key="prof.id" class="flex items-center">
                <div class="z-10 mx-2 bg-white">
                  <input :id="prof.id" name="BIPE" type="radio" :checked="prof.aka === defProf ? true : false" :value="prof.id" />
                  <label :for="prof.id" class="flex h-8 w-8 py-1 text-center border border-genome-dark-blue rounded-lg cursor-pointer">
                    <span class="m-auto">{{ prof.name }}</span>
                  </label>
                </div>
              </div>
            </div>
            <h5 class="text-sm text-genome-dark-blue">(B- Beginner, I- Intermediate, P- Proficient, E- Expert, O- Optional)</h5>
          </div>
        </div>
        <div class="flex items-center justify-end px-1">
          <div class="flex-shrink-0 ml-4 flex">
            <button @click="clearForm" class="mr-8 button font-medium text-genome-dark-blue">Cancel</button>
            <button @click="generatePolicy" :class="{ loading: isCreateNewPolicy }" :disabled="!ruleArray.length" class="h-10 button px-6 text-sm text-white bg-genome-dark-blue rounded">
              {{ policyId ? 'Update Policy' : 'Create Policy' }}
              <ring-loader />
            </button>
          </div>
        </div>
      </section>
      <!-- <section class="p-4 mx-auto mb-8 bg-white rounded-lg shadow">
        <h3 class="text-lg text-gray-800 font-bold">Generated Policy:</h3>
        <div class="flex items-center justify-end px-10">
          <span v-html="policyCond"></span>
        </div>
      </section> -->
    </div>
    <section class="p-4 mx-auto mb-8 bg-white rounded-lg shadow">
      <div class="flex items-center justify-between px-1">
        <h3 class="text-lg text-genome-dark-blue font-semibold my-4">Policy Table</h3>
        <button v-if="!isOpenWindow" @click="isOpenWindow = !isOpenWindow" class="py-2 px-4 text-sm text-white bg-genome-dark-blue rounded">Add Policy</button>
      </div>
      <PolicyTable @editPolicy="editPolicy" @editPolicyStatus="updatePolicyStatusFunc" />
    </section>
    <!-- <modal-comp ref="add-update-policy-popup" name="add-update-policy-popup" :show-close-btn="true"> -->
    <!-- <div class="p-4">
        <h1 class="p-4 border-b text-xl font-semibold text-genome-dark-blue">Add Skill</h1>
        <div class="flex text-genpact-text-grey text-left">
          <div class="relative">
            <div class="m-4">
              <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Skill Name</p>
              <input v-model="skillName" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded h-full" type="text" placeholder="Enter skill name..." />
            </div>
          </div>
          <div class="relative w-56">
            <SingleSelect model-name="archetype" badge-title="Archetype" :default-option="archetypeDef" :data-list="archeTypeList" />
          </div>
        </div>
        <div class="relative p-4 w-full">
          <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Skill Message</p>
          <input v-model="skillMessage" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter skill message..." />
        </div>
        <div class="relative p-4 w-full">
          <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-3">Skill Description</p>
          <textarea v-model="skillDescription" class="focus:outline-none px-4 py-2 text-sm leading-tight text-gray-700 bg-gray-50 border rounded w-full" type="text" placeholder="Enter skill description..." />
        </div>
        <div class="relative p-4 w-full flex justify-end space-x-4">
          <button class="button px-4 py-1 rounded text-sm bg-white text-blue-800 border">Cancel</button>
          <button
            @click="addSkillFunc()"
            :disabled="!skillName.length"
            :class="{ 'bg-genome-dark-blue border-genome-dark-blue': skillName.length }"
            class="button px-4 py-1 rounded text-sm bg-blue-300 text-white border border-blue-300"
          >
            Submit
          </button>
        </div>
      </div> -->
    <!-- </modal-comp> -->
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PolicyTable from '@/components/PolicyTable.vue';
// eslint-disable-next-line object-curly-newline
import { Trash2Icon, PlusIcon } from 'vue-feather-icons';
import ToggleButton from '../components/global/ToggleButton.vue';

export default {
  name: 'RuleEngine',
  components: {
    PolicyTable,
    Trash2Icon,
    PlusIcon,
    ToggleButton,
  },
  data() {
    return {
      PolicyName: '',
      searchPolicy: '',
      policyArray: [],
      policyCond: {},
      ruleArray: [],
      factMaster: 0,
      policyNameErr: '',
      factMasterError: false,
      isLoadingPolicies: false,
      isCreateNewPolicy: false,
      factOperatorError: false,
      factOperator: '',
      factOperatorList: [
        {
          title: 'Equal to',
          value: 'equal',
        },
        {
          title: 'Not-Equal to',
          value: 'not-equal',
        },
      ],
      factValues: '',
      factValuesError: false,
      initialTab: 'policies',
      switchedSearchTab: 'policies',
      isMandatory: false,
      proficienciesList: [],
      selectedFunc: [],
      selectedRole: [],
      isOpenWindow: false,
      defProf: 'beginner',
      defFuncVal: '',
      defRoleVal: '',
      policyId: null,
    };
  },
  watch: {
    skillsByMaster(val) {
      this.selectedFunc = val?.find((obj) => obj.name === 'Function')?.MasterHierarchy.filter((objj) => objj.parentId === null);
      this.proficienciesList = [...this.skillLevels]
        ?.sort((a, b) => {
          if (a.code < b.code) return -1;
          if (a.code > b.code) return 1;
          return 0;
        })
        ?.splice(0, 5);
    },
  },
  computed: {
    ...mapGetters(['mastersList', 'skillsByMaster', 'skillLevels', 'masterIdArr', 'subSkills']),
  },
  async beforeMount() {
    const userPayload = JSON.parse(localStorage.getItem('rbac_user'));
    if (userPayload && userPayload.pages && !userPayload.pages.some((obj) => obj.code === 6)) this.$router.push('/');
  },
  async mounted() {
    await this.getSkills();
  },
  methods: {
    ...mapActions(['getSkills', 'getSkillsByMasterId', 'createPolicy', 'updatePolicy', 'updatePolicyStatus']),
    switchedSearchTabFunc(status) {
      this.switchedSearchTab = status;
    },
    async clearForm() {
      this.PolicyName = '';
      this.policyNameErr = '';
      this.ruleArray = [];
      this.policyId = null;
      this.defFuncVal = '';
      this.defRoleVal = '';
      this.defProf = 'beginner';
      this.isMandatory = false;
      this.isOpenWindow = false;
    },
    async editPolicy(obj) {
      window.scrollTo(0, 0);
      this.isOpenWindow = true;
      this.policyId = obj?.id;
      this.PolicyName = obj?.name;
      if (obj.policyCondition) {
        this.ruleArray = [];
        this.ruleArray.push({
          id: 1,
          fact: 'function',
          operator: 'equal',
          value: '',
        });
        this.defFuncVal = obj?.policyCondition?.all[0].value;
        this.selectedRole = this.skillsByMaster?.find((objt) => objt.name === 'Function')?.MasterHierarchy.filter((objj) => objj.parentId === obj?.policyCondition?.all[0].value);
        this.defRoleVal = obj?.policyCondition?.all[1].value;
      }
      this.isMandatory = obj?.isMandatory;
      if (!obj.isMandatory && obj?.proficientId) this.defProf = obj?.Levels?.title?.toLowerCase();
    },

    selectedValue(id) {
      const val = document.getElementById(`value${id}`).value;
      console.log(val);
      this.selectedRole = this.skillsByMaster?.find((obj) => obj.name === 'Function')?.MasterHierarchy.filter((objj) => objj.parentId === val);
    },
    updateOperator($event) {
      const { value, id } = $event.target;
      switch (value) {
        case 'and':
          console.log('and', value, id);
          break;
        case 'or':
          console.log('or', value, id);
          break;
        default:
          break;
      }
    },
    addRule() {
      console.log(this.ruleArray.length);
      const id = this.ruleArray.length ? this.ruleArray[this.ruleArray.length - 1].id : 0;
      this.ruleArray.push({
        id: id + 1,
        fact: '',
        operator: '',
        value: '',
      });
    },
    removeRule(index) {
      console.log(index);
      this.ruleArray.splice(index, 1);
    },
    async newPolicy(policyObj) {
      const ele = document.getElementsByName('BIPE');
      let proficientValue;
      ele.forEach((el) => {
        if (el.checked) proficientValue = el.value;
      });
      let newPolicy = {
        name: this.PolicyName,
        groupId: '95e686e7-5651-4c92-8832-6dda6aec35d5',
        isMandatory: this.isMandatory,
        proficientId: this.isMandatory ? null : proficientValue,
        policyCondition: policyObj,
      };
      let resp = null;
      this.isCreateNewPolicy = true;
      if (this.policyId) {
        newPolicy = { ...newPolicy, id: this.policyId };
        resp = await this.updatePolicy(newPolicy);
      } else resp = await this.createPolicy(newPolicy);
      this.isCreateNewPolicy = false;
      if (resp && resp.message) {
        this.$store.dispatch('addToastNotifications', {
          text: resp.message,
          type: 'success',
          timer: 3000,
        });
        await this.getSkills();
        this.clearForm();
      }
    },
    async updatePolicyStatusFunc({ id, status }) {
      console.log(id, status);
      const resp = await this.updatePolicyStatus({ id, status });
      if (resp && resp.message) {
        this.$store.dispatch('addToastNotifications', {
          text: resp.message,
          type: 'success',
          timer: 3000,
        });
        await this.getSkills();
        this.clearForm();
      }
    },
    policyError() {
      const returnarr = [];
      if (!this.PolicyName.length) {
        this.policyNameErr = '*please enter new policy name';
        returnarr.push(true);
      } else {
        this.policyNameErr = '';
        returnarr.push(false);
      }

      const arr = ['fact', 'operator', 'value', 'subSkillValue'];
      this.ruleArray.forEach((obj) => {
        arr.forEach((objj) => {
          const ccv = document.getElementById(`${objj}${obj.id}`);
          if (ccv) {
            if (ccv.value === '') {
              document.getElementById(`error${objj}${obj.id}`).classList.remove('hidden');
              returnarr.push(true);
            } else {
              document.getElementById(`error${objj}${obj.id}`).classList.add('hidden');
              returnarr.push(false);
            }
          }
        });
      });
      return returnarr.some((obj) => obj === true);
    },
    async generatePolicy() {
      if (!this.policyError()) {
        this.policyNameErr = '';
        this.policyArray = [];
        // const fact = document.getElementById(`fact${this.ruleArray[0].id}`).value;
        // if (fact === 'function_role') {
        const subSkillVal = document.getElementById(`subSkillValue${this.ruleArray[0].id}`).value;
        const operator = document.getElementById(`operator${this.ruleArray[0].id}`).value;
        const obj1 = {
          fact: 'function',
          operator,
          value: document.getElementById(`value${this.ruleArray[0].id}`).value,
        };
        const subObj = {
          fact: 'role',
          operator,
          value: subSkillVal,
        };
        this.policyCond = { all: [obj1, subObj] };
        this.newPolicy(this.policyCond);
        // } else {
        //   this.ruleArray.forEach((obj) => {
        //     const ele = document.getElementsByName(`andor${obj.id}`);
        //     let cond = '';
        //     ele.forEach((el) => {
        //       if (el.checked) cond = el.value;
        //     });
        //     // console.log(obj.id, cond);
        //     const ruleObj = {
        //       fact: document.getElementById(`fact${obj.id}`).value,
        //       operator: document.getElementById(`operator${obj.id}`).value,
        //       value: document.getElementById(`value${obj.id}`).value,
        //     };
        //     if (cond === 'and') {
        //       this.policyArray = [
        //         {
        //           all: this.policyArray,
        //         },
        //         ruleObj,
        //       ];
        //       // this.policyArray.push({ all: abc });
        //     } else if (cond === 'or') {
        //       this.policyArray = [
        //         {
        //           all: this.policyArray,
        //         },
        //         ruleObj,
        //       ];
        //       // this.policyArray.push({ any: abc });
        //     } else {
        //       this.policyArray.push({ ruleObj });
        //     }
        //   });
        // }
      }
    },
  },
};
</script>

<style lang="postcss">
.select-radio-input {
  /* @apply space-x-2; */
  input {
    @apply fixed left-0 opacity-0 invisible;
    margin-left: -9999px;

    &:checked {
      /* stylelint-disable-next-line */
      + label {
        @apply text-white bg-genome-dark-blue;
      }
    }
  }
}
</style>
