<template>
  <main :data-from-date="payload.fromDate">
    <div v-if="initialDataLoading" class="h-72 flex flex-col items-center justify-center text-center">
      <ring-loader class="mb-5" height="35px" width="35px" color="rgb(0, 173, 207)" />
      <h5 class="text-lg">Preparing dashboard...</h5>
      <p class="mb-5">Please be patient</p>
    </div>
    <div v-else-if="dataLoadError.length" class="flex flex-col items-center justify-center h-full text-center">
      <i class="icon os-icon os-icon-robot-1" />
      <h5 class="text-lg">{{ dataLoadError }}</h5>
      <button class="px-4 py-2 mt-5 border border-gray-700 rounded" @click.prevent="reloadWindow">Reload window</button>
    </div>
    <section v-else>
      <section class="flex flex-wrap items-center justify-between w-full p-6 bg-white rounded-lg shadow">
        <div class="flex items-center justify-between mb-3">
          <h3 class="text-lg text-gray-800">Download Report</h3>
          <!-- <p class="text-gray-dark flex items-center text-sm">
            Last updated on {{ moment(currentTime).format('hh:mm A DD MMM') }}
          </p> -->
        </div>
        <div class="flex flex-wrap gap-3">
          <div v-if="!isSpecial" class="date-select-actions md:flex items-center">
            <!-- eslint-disable-next-line vue/max-attributes-per-line -->
            <input id="todayRadioInput" type="radio" name="dateRangeRadioSelector" value="today" @change="updateDate" />
            <label for="todayRadioInput" class="nav-item m0">
              <span class="nav-link">Today</span>
            </label>
            <input id="7daysRadioInput" type="radio" name="dateRangeRadioSelector" value="7days" @change="updateDate" />
            <label for="7daysRadioInput" class="nav-item m0">
              <span class="nav-link">7 Days</span>
            </label>
            <input id="14daysRadioInput" type="radio" name="dateRangeRadioSelector" value="14days" @change="updateDate" />
            <label for="14daysRadioInput" class="nav-item m0">
              <span class="nav-link">14 Days</span>
            </label>
            <input id="monthRadioInput" type="radio" name="dateRangeRadioSelector" value="month" @change="updateDate" />
            <label for="monthRadioInput" class="nav-item m0">
              <span class="nav-link">Last Month</span>
            </label>
          </div>
          <div class="relative my-1">
            <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">OHR Id</p>
            <input v-model="ohrId" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded h-full" type="text" placeholder="All OHR ID's" @change="invalidOhrId" />
          </div>
          <div v-if="!isSpecial" class="relative my-1">
            <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">From Date</p>
            <input id="startDate" v-model="startDate" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded" type="date" @change="resetInputs" />
          </div>
          <div v-if="!isSpecial" class="relative my-1">
            <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">To Date</p>
            <input id="endDate" v-model="endDate" class="focus:outline-none px-4 py-2 text-sm font-semibold leading-tight text-gray-700 bg-gray-100 border rounded" type="date" @change="resetInputs" />
          </div>
          <div class="relative my-1 flex flex-wrap gap-3">
            <div class="relative">
              <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">Type</p>
              <select v-model="selRepType" @change="selectedReportFunc()" class="focus:outline-none block w-48 h-full px-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none capitalize">
                <option value="">All</option>
                <option v-for="(report, index) in reportTypes" :key="index" :value="report.reporttype" class="capitalize">
                  {{ report.reporttype }}
                </option>
              </select>
              <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div v-if="selRepType.length && reportsList[selRepType].length" class="relative">
              <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">Report type</p>
              <select v-model="selectedReport" class="focus:outline-none block w-48 h-full px-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none">
                <option value="" disabled>-- Select report type --</option>
                <option v-for="x in reportsList[selRepType]" :key="x.id" :value="x.report" class="capitalize">
                  {{ x.title }}
                </option>
              </select>
              <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="isSpecial" class="relative my-1">
            <p class="bg-white ml-2 px-2 py-0.5 absolute rounded shadow-custom text-xs -mt-4">Group</p>
            <select v-model="selectedDDValues" class="focus:outline-none block w-48 h-full px-4 py-2 pr-8 text-sm font-medium leading-tight text-gray-700 bg-gray-100 border rounded appearance-none">
              <option value="" disabled>-- Select Group --</option>
              <option v-for="(x, index) in groups" :key="index" :value="x.Group" class="capitalize">
                {{ x.Group }}
              </option>
            </select>
            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
              <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <button
            v-if="(startDate && endDate && selectedReport && (selRepType === 'special' ? selectedDDValues !== '' : true)) || selectedReport === 'journey_completion_report'"
            :class="{ loading: request.keys.includes('executeAthenaQuery') && request.inProgress }"
            :disabled="request.keys.includes('executeAthenaQuery') && request.inProgress"
            class="button px-6 h-10 text-sm text-white bg-blue-500 rounded"
            @click="executeAthenaQuery(payload)"
          >
            Generate new report
            <ring-loader />
          </button>
        </div>
      </section>
      <ExecutionTable :filters="payload" @clearFilters="clearFilters" @refreshTableData="refreshTableData" />
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import calenderMixin from '@/mixins/calender-mixin';
import moment from 'moment';

export default {
  name: 'Download',
  components: {
    ExecutionTable: () => import('@/components/ExecutionTable.vue'),
  },
  mixins: [calenderMixin],
  data() {
    return {
      currentTime: new Date(),
      selectedReport: '',
      selectedDDValues: '',
      initialDataLoading: false,
      dataLoadError: '',
      selRepType: '',
      ohrId: '',
      correctOHR: 0,
      endDate: null,
      startDate: null,
    };
  },
  computed: {
    ...mapGetters(['reportTypes', 'groups', 'request', 'executions', 'reportsList']),
    payload() {
      const payload = {
        ohrId: this.correctOHR,
        fromDate: this.startDate ? moment(this.startDate).format('YYYY-MM-DD 00:00:00') : null,
        toDate: this.endDate ? moment(this.endDate).format('YYYY-MM-DD 23:59:59') : null,
        report: this.selectedReport,
        program: this.selectedDDValues,
      };
      if ((payload.toDate && payload.fromDate) || (!payload.toDate && !payload.fromDate)) this.fetchQueryExecHistory(payload);
      return payload;
    },
    isSpecial() {
      if (this.selRepType === 'special') return true;
      return false;
    },
  },
  watch: {
    selectedReport(val) {
      const ids = ['startDate', 'endDate', 'todayRadioInput', '7daysRadioInput', '14daysRadioInput', 'monthRadioInput'];
      if (val === 'journey_completion_report' || val === 'solidify_dataset_v2' || val === 'solidify_dataset' || val === 'special_program_report_group' || val === 'special_program_report') {
        this.startDate = '2019-01-15';
        this.endDate = moment().format('YYYY-MM-DD');
        this.resetInputs();
        ids.forEach((i) => {
          document.getElementById(i).disabled = true;
        });
      } else {
        ids.forEach((i) => {
          document.getElementById(i).disabled = false;
        });
      }
    },
  },
  async beforeMount() {
    this.initialDataLoading = true;
    try {
      await this.fetchUserRole();
      await this.fetchAllReportsLists();
      // await this.getGroups();
    } catch (e) {
      console.warn(e);
      this.dataLoadError = 'Critical error, please reload the window or contact support.';
      this.$store.dispatch('addToastNotifications', {
        text: this.dataLoadError,
        type: 'error',
        timer: 4000,
      });
    }
    this.initialDataLoading = false;
  },
  methods: {
    ...mapActions(['executeAthenaQuery', 'fetchQueryExecHistory', 'fetchUserRole', 'fetchAllReportsLists']),
    clearFilters() {
      this.ohrId = '';
      this.correctOHR = 0;
      this.endDate = '';
      this.startDate = '';
      this.selectedReport = '';
      this.selRepType = '';
      this.selectedDDValues = '';
      this.resetInputs();
    },
    refreshTableData() {
      this.fetchQueryExecHistory(this.payload);
    },
    selectedReportFunc() {
      if (this.selRepType === 'special') this.selectedReport = 'special_program_report';
      else this.selectedReport = '';
      this.selectedDDValues = '';
    },
    resetInputs() {
      document.querySelectorAll('input[name="dateRangeRadioSelector"]').forEach((x) => {
        const elem = x;
        elem.checked = false;
      });
    },
    invalidOhrId() {
      const isValidOHR = !this.ohrId.toString().match(/^[0-9]+(,[0-9]+)*$/);
      if (isValidOHR) {
        this.$store.dispatch('addToastNotifications', { text: 'Invalid OHRID!', timer: 2000, type: 'error' });
        this.correctOHR = 0;
      } else this.correctOHR = this.ohrId;
      return isValidOHR;
    },
    reloadWindow() {
      window.location.reload();
    },
  },
};
</script>

<style lang="postcss">
.date-select-actions {
  /* @apply space-x-2; */
  input {
    @apply fixed left-0 opacity-0 invisible;
    margin-left: -9999px;

    &:checked {
      /* stylelint-disable-next-line */
      + label {
        @apply bg-blue-50 text-blue-700;
      }
    }
  }
  label {
    @apply inline-block rounded-lg font-medium leading-6 px-3 focus:outline-none hover:text-blue-600 focus:text-blue-600 cursor-pointer text-sm border border-gray-200 text-gray-700;
    &:not(:first-of-type) {
      @apply ml-2;
    }
  }
}
</style>
