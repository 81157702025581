import accountsActionModule from './queries-actions';

const initializeState = () => ({
  reportsList: {},
  LSreportsList: [],
  executions: [],
  groups: [],
  reportTypes: [],
  libraries: [],
  versions: {},
  total: 0,
});
const removeDuplicates = (array, mapKey) => {
  if (array.length) {
    const abc = [];
    const map = new Map();
    array.map((item) => {
      if (!map.has(item[mapKey])) {
        map.set(item[mapKey], true); // set any value to Map
        if (item[mapKey] !== null && item[mapKey] !== undefined && item[mapKey] !== '') abc.push(item);
      }
      return abc;
    });
    return abc;
  }
  return [];
};
export default ({ $http }) => ({
  state: {
    ...initializeState(),
  },
  mutations: {
    setQueryExecHistoryInStore(state, payload) {
      state.executions = payload.records || [];
      state.total = payload.total || 0;
    },
    setReportsListsInStore(state, payload) {
      state.reportTypes = removeDuplicates(payload.records, 'reporttype'); // send (array, mapKey)
      // state.reportTypes = [{ reportType: 'immerse' }]; // TODO: remove this line
      if (state.reportTypes.length) {
        state.reportTypes.map((obj) => {
          const reportArr = payload.records.filter((objj) => obj.reporttype === objj.reporttype);
          state.reportsList = { ...state.reportsList, [obj.reporttype]: reportArr || [] };
          return true;
        });
      }
    },
    setLSReportsInStore(state, payload) {
      state.LSreportsList = payload.message || [];
    },
    setGroups(state, payload) {
      state.groups = removeDuplicates(payload, 'Group'); // send (array, mapKey)
    },
    clearLibraries(state) {
      state.libraries = [];
    },
    setLibraries(state, payload) {
      state.libraries.push({
        ...payload.library,
        selectedVers:
          payload.library.version === 'latest'
            ? payload.libData.versions.filter((objj) => !objj.includes('alpha') && !objj.includes('beta') && !objj.includes('rc'))
            : payload.libData.versions.filter((objj) => objj.includes(payload.library.version)),
        versions: payload.libData.versions,
        tags: payload.libData.tags,
      });
    },
    changeSelectedTag(state, payload) {
      state.libraries.map((obj) => {
        if (obj.library === payload.library) {
          // eslint-disable-next-line no-param-reassign
          if (payload.tag === 'latest') obj.selectedVers = obj.versions.filter((objj) => !objj.includes('alpha') && !objj.includes('beta') && !objj.includes('rc'));
          // eslint-disable-next-line no-param-reassign
          else obj.selectedVers = obj.versions.filter((objj) => objj.includes(payload.tag));
        }
        return true;
      });
    },
    updateQueryExecHistoryInStore(state, payload) {
      state.executions = payload.records.map((x) => {
        let obj = { ...x };
        if (obj.id === payload.id) {
          obj = {
            ...obj,
            ...payload,
          };
        }
        return obj;
      });
    },
  },
  actions: accountsActionModule({ $http }),
  getters: {
    executions: (state) => state.executions,
    reportsList: (state) => state.reportsList,
    reportTypes: (state) => state.reportTypes,
    LSreportsList: (state) => state.LSreportsList,
    groups: (state) => state.groups,
    versions: (state) => state.versions,
    libraries: (state) => state.libraries,
  },
});
